/* You can add global styles to this file, and also import other style files */

@import "variables";

html,
body {
    height: 100%;
}

body {
    margin: 0 auto;
    padding: auto;
    //font-family: 'Nunito', 'Verdana', 'Geneva', sans-serif !important;
    font-family: 'Nunito', sans-serif !important;
}

img{
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    //font-family: 'Nunito', 'Georgia', serif !important;
    font-family: 'Nunito', serif !important;
    //font-weight: 700;
}




@media only screen and (max-width: 799px) {
    .hide-large {
        display: none;
    }
}

@media only screen and (min-width: 800px) {
    .hide-small {
        display: none;
    }
}

.content {
    padding-top: 100px;
    background-color: $color_white;

    .btn-outline-primary {
        //border-color: $color_white;
        //color: $color_white;
    }
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}