@media only screen and (max-width: 435px) {
    .Annonce {
        margin-top: 80px;
        text-align: center;
        .annonce_search{
            justify-content: space-around;
        }
        .annonce_type{
            width: 200px;
        }
        .annonce_type-list{
            text-align: left;
            width:100%;
        }
    
        .annonce_back{
            color: #593e96;
            margin-left: 4%;
            text-align: left;
        }
    
        .title{
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 8%;
        }
    
        .ads_new {
            padding-top: 24px;
            padding-bottom: 24px;
            .ads_new-button {
                background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                width: 200px;
                }
            }
    
        .annonce_card{
            text-align: left;
            color: rgba(68, 76, 165, 0.85)eb;
            border: 1px solid #0000003d;
            border-radius: 10px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;
            border-radius: 11px;
            padding: 12px;
            margin: 12px;
            margin-bottom: 24px;
        }
    
        .annonce_card-title{
            font-size: 1.2rem;
            font-weight: 800;
            color: #583d95;
        }
    
        .fa-tag, .fa-city{
            color: #583d95;
            font-weight: 800;
        }
        .annonce_card_info{
            margin-top: 12px;
            justify-content: space-between;
            align-items: flex-end;
        }
        .annonce_card_pics-img{
            width:100px;
            margin-right: 12px;
        }
        .modal-picture, .img-modal{
            width: 100%;
        }
        .annonce_edit-button{
            background: transparent;
            border: none;
            float: right;
            cursor: pointer;
            .fa-edit{
                color: #583d95;
                font-size: 1rem;
            }
        }
        .annonce_contact{
            .annonce_contact-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: #583d95;
                width: 100px;
                box-shadow: 0 0 3px 1px #61439ebd;
            }
            .annonce_delete-button{
                background: transparent;
                border: none;
                float: right;
                cursor: pointer;
                .fa-trash-alt{
                    color: #c30000;
                    font-size: 1.2rem;
                }
            }
        }
        
    }
}

@media only screen and (min-width: 436px) {
    .Annonce {
        margin: auto;
        margin-top: 80px;
        background: white;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        text-align: center;
        padding: 24px;
        margin-bottom: 3%;
        width: 704px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;
    }
    .annonce_search{
        justify-content: space-around;
    }
    .annonce_type{
        width: 100px;
    }
    .annonce_type-list{
        text-align: left;
        width:100%;
    }

    .annonce_back{
        color: #593e96;
        margin-left: 4%;
        text-align: left;
        cursor: pointer;
    }

    .title{
        text-align: center;
        color: #3E4594;
        font-size: 25px;
        margin-bottom: 12px;
    }

    .ads_new {
        padding-top: 24px;
        padding-bottom: 24px;
        .ads_new-button {
            background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            width: 200px;
            }
        }

    .annonce_card{
        text-align: left;
        margin: auto;
        color: rgba(68, 76, 165, 0.85)eb;
        border: 1px solid #0000003d;
        border-radius: 10px;
        box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;
        border-radius: 11px;
        padding: 12px;
        margin-bottom: 24px;
        max-width: 500px;
    }

    .annonce_card-title{
        font-size: 1.2rem;
        font-weight: 800;
        color: #583d95;
    }

    .fa-tag, .fa-city{
        color: #583d95;
        font-weight: 800;
    }
    .annonce_card_info{
        margin-top: 12px;
        justify-content: space-between;
        align-items: flex-end;
    }
    .annonce_card_pics-img{
        width:100px;
        margin-right: 12px;
    }
    .modal-picture, .img-modal{
        width: 100%;
    }
    .annonce_edit-button{
        background: transparent;
        border: none;
        float: right;
        cursor: pointer;
        .fa-edit{
            color: #583d95;
            font-size: 1rem;
        }
    }

    .annonce_contact{
        .annonce_contact-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: #583d95;
            width: 100px;
            box-shadow: 0 0 3px 1px #61439ebd;
            cursor: pointer;
        }
        .annonce_delete-button{
            background: transparent;
            border: none;
            float: right;
            cursor: pointer;
            .fa-trash-alt{
                color: #c30000;
                font-size: 1.2rem;
            }
        }
        
    }
}