@import "../../styles";

@media only screen and (max-width: 435px) {
  .profile{
    margin-top: 80px;
    
    .anchor-card {
      text-decoration:none;
    }

    .signup_back{
      color: #593e96;
      text-align: left;
      margin-left: 4%;
    }

    .profile_card{
      padding:0;
    }

    .title {
      text-align: center;
      color: $title_color;
      font-size: 26px;
      margin-bottom: 12px;
    }

    .card {
        margin-bottom: 24px;
        background-color: #ecdff626;
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0,0,0,0.12);

        border: #BDC1E7 solid 0.5px;
        border-radius: 9px;
      }

      .card-title{
        color: $title_color;
        text-align: left;
      }

      .card-text {
        color: $text_color;
        text-align: left;
      }

  }
}

@media only screen and (min-width: 436px) {
.profile{
  margin: auto;
  margin-top: 80px;
    background: white;
    border: #3E4594 solid 1px;
    border-radius: 8px;
    text-align: center;
    padding: 24px;
    margin-bottom: 3%;
    width: 504px;
    box-shadow: 3px 5px 10px 1px #cbc7d0;
    
    .title {
        text-align: center;
        color: $title_color;
        font-size: 26px;
      margin-bottom: 12px;
      }

      .profile_card{
        padding:0;
      }

      .signup_back{
        color: #593e96;
        text-align: left;
        cursor: pointer;
      }

      .anchor-card {
        text-decoration:none;
      }

      .card {
        box-shadow: 0 6px 18px 0 #E3E5F6;
        width: 450px;
        margin-bottom: 48px;
        border: #BDC1E7 solid 0.5px;
        border-radius: 9px;
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0,0,0,0.12);

      }

      .card-title{
        color: $title_color;
        text-align: left;
      }

      .card-text {
        color: $text_color;
        text-align: left;
      }
    }
}