@media only screen and (max-width: 435px) {
    .health_profile {
        color: rgba(68, 76, 165, 0.85);
        font-weight: 700;
    }

    .health_time--hide {
        opacity: 0;
        .health_time {
            display: none
        }
    }

    .health_profile_sentence{
        display: flex;
            align-items: flex-end;
            justify-content: center;
    }

    .health_time--appear {
        opacity: 1;
        transition: opacity .5s ease-in;
        margin-top:12px;
        .health_time {
            color: rgba(68, 76, 165, 0.85);
            font-weight: 700;
        }
    }

    .health_time_sentence {
        display: flex;
            align-items: flex-end;
            justify-content: center;
    }

    .health_profile_type,
    .health_time_type {
        margin-right: 8px;
        margin-left: 8px;
    }

    .health_profile_question,
    .health_time_question {
        margin-right: 5px;
    }

    .health_button--hide {
        opacity: 0;

        .insurances_buy-button {
            display: none;
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }

    .health_button--appear {
        opacity: 1;
        transition: opacity .5s ease-in;
        margin-top: 24px;

        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }
    .health_box{
        width: 100px;
        height: 74px;
        margin-right: 12px;
        padding: 12px;
        border: 1px solid #BDC1E7;
        border-radius: 9px;
        box-shadow: 1px 1px 3px 1px lightgrey;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .health_logo{
        max-width: 290px;
    }

    .health_logo-img{
        width: 100%;
    }

    .crystal_info{
        margin-top: 38px;
        margin-bottom: 42px;
        background: #e2e1e1;
        padding: 12px;
    }
    .health_document_row{
        max-width: 200px;
        margin: auto;
    }
    .health_document-container{
        width: 200px;
        border: 1px solid #5915ad;
        color: #5915ad;
        padding: 6px;
        margin-top: 24px;
        border-radius: 7px;
        cursor: pointer;
    }

    .health_title{
        font-size: 1.1rem;
        font-weight: 600;
        color: #5915ad;
    }
    .health_text{
        color: rgba(68, 76, 165, 0.85);
    }
}

@media only screen and (min-width: 436px) {
    .Health{
        margin-top: 42px;
    }
    
    .health_profile {
        display: flex;
        align-items: baseline;
        justify-content: center;
        color: rgba(68, 76, 165, 0.85);
        font-weight: 700;
    }

    .health_profile_sentence, .health_time_sentence{
        display: flex;
            align-items: flex-end;
            justify-content: center;
    }

    .health_time--hide {
        opacity: 0;

        .health_time {
            display: none
        }
    }

    .health_time--appear {
        opacity: 1;
        transition: opacity .5s ease-in;

        .health_time {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 700;
            margin-top: 42px;
        }
    }

    .health_profile_type,
    .health_time_type {
        margin-right: 8px;
        margin-left: 8px;
    }

    .health_profile_question,
    .health_time_question {
        margin-right: 5px;
        text-align: left;
    }


    .health_button--hide {
        opacity: 0;

        .insurances_buy-button {
            display: none;
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }

    .health_button--appear {
        opacity: 1;
        transition: opacity .5s ease-in;
        margin-top: 24px;

        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }

    .health_box{
        width: 100px;
        height: 74px;
        margin-right: 12px;
        padding: 12px;
        border: 1px solid #BDC1E7;
        border-radius: 9px;
        box-shadow: 1px 1px 3px 1px lightgrey;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .health_logo{
        margin-bottom: 24px;
    }

    .crystal_info{
        margin-top: 38px;
        margin-bottom: 62px;
        background: #e2e1e1;
        padding: 12px;
    }

    .health_document_row{
        display: flex;
        justify-content: space-evenly;
    }

    .health_document-container{
        width: 200px;
        border: 1px solid #5915ad;
        color: #5915ad;
        padding: 6px;
        border-radius: 7px;
        cursor: pointer;
    }

    .health_title{
        font-size: 1.1rem;
        font-weight: 600;
        color: #5915ad;
    }
    .health_text{
        color: rgba(68, 76, 165, 0.85);
    }
}