.taxi_description{
    text-align: left;
}

.taxi_provider-img{
    width: 250px;
}
.taxi{
    text-align: left;
}
.taxi_title{
    font-weight: 600;
    color:rgb(68, 76, 165)
}

.taxi_depart{
    max-width: 300px;
    margin: auto;
    margin-bottom: 42px;
}

.taxi_airport{
    width: 300px;
}

.taxi_provider_logo{
    width: 250px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
}
.taxi_document{
    display: flex;
    justify-content: space-evenly;
}

.taxi_provider-img-price{
    width: 700px;

}

.taxi_provider_logo-price{
    width: 700px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
}

@media only screen and (max-width: 435px) {
    .taxi_provider-img-price{
        width: 350px;
    
    }
    
    .taxi_provider_logo-price{
        width: 350px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}