@import "../../styles";
//#3f2751
//#250b38
.menu {
    text-align: center;
    .message-disconnect {
        color: #3f2751;        
    }
    .message {
        color: #ffffffb8;        
    }
    .menu_button-home, .menu_button-login, .menu_button-services, .menu_button-signup,
    .menu_button-profile, .menu_button-logout, .menu_button-wishlist {
    padding: 16px;
    text-align: left;
    }
    a {
      text-decoration:none;
    }

    .button {
    color: #ffffffb8;
    font-weight: 300;
    width: 100%;
    text-align: left;
    display: block;
    }
    .my-menu{
    background: white;
        padding: 10px;
        font-size: 1.5rem;
        color: #3f2751;
    }
}

.language{
    text-align: center;
}
.language-title{
    color: #ffffffb8;

}
.language_flag{
    margin-top: 3%;
}

.language_flag-img{
    cursor: 'pointer';
    margin-left: auto;
    width: 10%;
    border-radius: 19px;
    box-shadow: 4px 2px 16px 1px rgba(25, 23, 23, 0.73);
}
