@import "../../../styles";

@media only screen and (max-width: 435px) {
    .Telecom {
        margin-top: 80px;
        text-align: center;
        padding: 8px;

        .internet {
            visibility: hidden;
            opacity: 0;
        }

        .linemob {
            justify-content: space-evenly;
        }

        .internet--appear {
            visibility: visible;
            opacity: 1;
            transition: opacity .5s ease-in;
        }

        .operator {
            opacity: 0;
        }

        .operator--appear {
            opacity: 1;
            transition: opacity .5s ease-in;
        }

        .telecom {
            visibility: hidden;
            opacity: 0;
        }

        .linemob {
            font-size: 14px !important;
        }

        .telecom--appear {
            opacity: 1;
            visibility: visible;
            transition: opacity .5s ease-in;
        }

        .select {
            width: 180px;
            text-align: left;
            margin-bottom: 24px;
            margin-top: 24px;
        }

        .telecom_phone_brand,
        .telecom_phone_color,
        .telecom_phone_size,
        .telecom_phone {
            width: 170px;
            text-align: left;
            margin-bottom: 24px;
            margin-top: 12px;
            padding-left: 0;
        }

        .telecom_phone_brand-list,
        .telecom_phone_color-list,
        .telecom_phone_size-list,
        .telecom_phone-list {
            width: 170px;
        }

        .telecom_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
        }

        .telecom_sort_price {
            text-align: right;
            color: grey;
            font-size: 0.8rem;
            font-weight: 200;

            .fa-sort {
                color: #836bc1bf;
            }
        }

        .telecom_operator-list {
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }

        .telecom_operator-logo {
            padding: 0;
            padding-bottom: 14px;
            text-align: left;
            margin-top: 12px;
        }

        .telecom_operator-details {
            text-align: left;
            margin-bottom: 9px;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 500;
            font-size: 15px;
        }

        .telecom_operator-details-wrap {
            max-width: 320px;
            margin-top: 12px
        }

        .telecom_operator-price {
            line-height: 1em;
            position: relative;
            outline: 0;
            border: 0;
            color: rgba(68, 76, 165, 0.85);
            text-align: center;
            height: 1.5em;

            // opacity: .5;
            &:before {
                content: '';
                // use the linear-gradient for the fading effect
                // use a solid background color for a solid bar
                background: linear-gradient(to right, transparent, rgba(68, 76, 165, 0.85), transparent);
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 1px;
            }

            &:after {
                content: attr(data-content);
                position: relative;
                display: inline-block;
                font-weight: 700;
                padding: 0 .5em;
                line-height: 1.5em;
                font-size: 1.3rem;
                color: #583d95;
                // this is really the only tricky part, you need to specify the background color of the container element...
                background-color: #fcfcfa;
            }
        }

        .telecom_phone-logo {
            width: 100px;
            height: 100px;
            margin-left: 15px;
            text-align: left;
        }

        .telecom_phone-details {
            word-break: break-word;
            text-align: left;
            color: rgba(68, 76, 165, 0.85);
            position: absolute;
            margin-left: 120px;
            font-weight: 500;
            line-height: 16px;
            font-size: 15px;
        }

        .telecom_operator-info {
            margin-bottom: 24px;
        }

        .telecom_phone-price {
            line-height: 1em;
            position: relative;
            outline: 0;
            border: 0;
            color: rgba(68, 76, 165, 0.85);
            text-align: center;
            height: 1.5em;

            // opacity: .5;
            &:before {
                content: '';
                // use the linear-gradient for the fading effect
                // use a solid background color for a solid bar
                background: linear-gradient(to right, transparent, rgba(68, 76, 165, 0.85), transparent);
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 1px;
            }

            &:after {
                content: attr(data-content);
                position: relative;
                display: inline-block;
                font-weight: 700;
                padding: 0 .5em;
                line-height: 1.5em;
                font-size: 1.3rem;
                color: #583d95;
                // this is really the only tricky part, you need to specify the background color of the container element...
                background-color: #fcfcfa;
            }
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
        }

        .subtitle {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
        }

        .title-card {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
            font-weight: 700;
        }

        .subtitle-card {
            text-align: right !important;
            color: #3e45949c;
            font-size: 18px;
            font-weight: 400;
        }

        .headerDivider {
            border-left: 0px solid #e0dfdf;
            border-right: 1px solid #e0dfdf;
            height: 41px;
            position: inherit;
        }

        .telecom_forfait {
            display: flex;
            justify-content: space-evenly;
        }

        .box {
            height: 80%;
            border-radius: 9px;
            background-color: #ecdff626;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: #BDC1E7 solid 0.5px;
            margin-bottom: 15px;
            margin-top: 15px;
            text-align: center;
            height: 119px;
            width: 140px;
            color: lightgrey;
            flex: 1 0 20%;
            margin-right: 6px;
            margin-left: 6px;
        }

        .ribbon {
            width: 132px;
            height: 40px;
            background: #8c60c6cc;
            top: 150px;
            left: -10px;
            color: white;
            transform: translate(-17%, 42%);
            padding: 7px;
        }

        .ribbon:before {
            content: "";
            position: absolute;
            top: -10px;
            left: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid #724ea2cc;
            border-top: 5px solid transparent;
            border-bottom: 5px solid #724ea2cc;
        }

        .ribbon:after {
            content: "";
            position: absolute;
            top: 0px;
            right: -40px;
            border-left: 20px solid #8c60c6cc;
            border-right: 20px solid transparent;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
        }

        .logo-box {
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;

            .fa-mobile-android-alt,
            .fa-file-alt,
            .fa-sim-card {
                font-size: 2.5rem;
            }
        }

        .text-box {
            padding: 10px;
            font-size: 16px;
        }

        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 16px;
            text-align: justify;
        }

        .media {
            object-fit: cover;
            height: 150px;
        }

        .div-card {
            margin-left: 7%;
            width: 40%;
            min-height: 490px;
        }

        .telecom_operator-checkbox {
            opacity: 0;
            visibility: hidden;
            margin: auto;
        }

        .telecom_operator-checkbox--appear {
            opacity: 1;
            visibility: visible;
            margin: auto;
            transition: opacity .5s ease-in;
        }

        .card-telecom {
            position: relative;
            border: 1px solid #0000000a;
            border-radius: 11px;
            padding: 3px;
            padding-right: 12px;
            padding-left: 12px;
            background-color: white;
            margin-top: 17px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;

            .button {
                background-color: #8a5fc6fc;
                border-radius: 100px;
                color: #ffffff;
                max-width: 96px;
            }

            .card_price-wrap {
                //        text-align: center;
                justify-content: center;
                justify-content: space-between;
                display: flex;
                margin-top: 12px;
                align-items: center;
            }

            .promo {
                padding: 0;
                margin: 0;
            }

            .img-promo {
                height: 70px !important;
                margin-right: 15px
            }

            .card-display-details-promo {
                display: flex;
                justify-content: space-between;
                margin-top: 74px;
                align-items: center;
            }

            .card-display {
                display: unset;
            }

            .card-display-promo {
                display: unset;
            }

            .telecom_operator-logo--img {
                height: 22px;
                vertical-align: middle;
            }
        }

        .telecom_promo {
            overflow-x: auto;
            height: 390px;
            -webkit-overflow-scrolling: touch;
        }

        .telecom_promo-container {
            display: inline-flex;
            min-width: 390px;
            padding: 5px;
            margin-right: 12px;
        }

        .card-price {
            color: #583d95;
            font-weight: 900;
            font-size: 28px;
            text-align: left;
            margin-bottom: 12px;
        }

        .card-old-price {
            color: #583d95;
            text-align: left;
            font-size: 15px;
        }

        .card-unit {
            color: rgba(68, 76, 165, 0.85);
            margin: auto;
            font-weight: 500;
            font-size: 14px;
        }

        .card-eng-m {
            color: rgba(68, 76, 165, 0.85);
            margin: auto;
            font-weight: 500;
            font-size: 12px;
        }

        .telecom_operator-details-wrap-prep {
            width: 180px;
            margin-top: 12px;
            display: inline-block;
            vertical-align: middle;
        }

        .card_zone {
            display: inline-block;
            vertical-align: middle;
        }

        .card-eng-d {
            display: none;
        }

        .card-eng-d-uk {
            display: none;
        }

        .card_name-m {
            color: rgba(68, 76, 165, 1);
            margin-left: 12px;
            font-weight: 700;
            font-size: 14px;
        }

        .card_name-com-m {
            display: none;
        }

        .card_name-com {
            color: rgba(68, 76, 165, 1);
            margin-left: 12px;
            font-weight: 700;
            font-size: 17px;
            position: absolute;
            right: 0;
            width: 210px;
            top: 14px;
        }

        .card-sim {
            display: inline-flex;
            color: rgba(68, 76, 165, 0.85);
            text-align: left;
            font-size: 11px;
            font-weight: 500;
        }

        .telecom_icon {
            width: 103px;
            padding: 0;
        }

        .help-button {
            background: transparent;
            border: 0;
        }
    }

    .prepaid_price {
        font-size: 34px !important;
    }

    .footer-telecom {
        margin-bottom: 3%;

        .button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }

    .service_disabled {
        opacity: 0.2;
        pointer-events: none;
    }

    .sky_details {
        font-size: 0.8rem !important;
        /* display: block; */
        width: 100% !important;
    }

    .sky_details-p {
        margin: 0;
    }

    .card_issoyo {
        opacity: 0;
        visibility: hidden;
        margin: auto;
    }

    .card_issoyo_appear {
        opacity: 1;
        visibility: visible;
        transition: opacity .5s ease-in;
        margin: auto;
    }

    .card_bg {
        text-align: left;
        background-color: white;
        border: 1px solid #0000000a;
        border-radius: 11px;
        padding: 12px;
        box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;
        margin-bottom: 24px;
        min-height: 355px;
        max-height: 355px;
    }

    .card_title {
        font-size: 1.1rem;
        font-weight: 900;
        height: 52px;
        color: #3E4594;
    }

    .card_info,
    .card_date {
        display: flex;
        color: rgba(68, 76, 165, 0.85);
    }

    .card_desc {
        margin-left: 12px;
        height: 144px;
    }

    .card_desc-text {
        overflow: hidden;
        max-height: 144px;
        word-wrap: break-word;
    }

    .card_place {
        margin-bottom: 24px;
        color: rgba(68, 76, 165, 0.85);
        font-size: .9rem;
        height: 42px;
    }

    .card_desc-button {
        position: relative;
        float: right;
        margin-top: 12px;

        .button {
            background-color: #8a5fc6fc;
            border-radius: 100px;
            color: #ffffff;
            max-width: 96px;
        }
    }

    .card_container_footer{
        display: flex;
    justify-content: space-between;
    }

    .wrapper {
        margin: auto;
        width: 300px;
    }

    .wrapper_mobile{
        margin-left: 20px;
        margin-right: 20px;
        margin: auto;
            width: 300px;
    }

    .fa-chevron-right {
        position: relative;
        float: right;
        top: 40px;
        font-size: 2.5rem;
        right: 15px;
        opacity: 0.5;
        z-index: 10;
    }

    .fa-chevron-left {
        position: relative;
        top: 40px;
        float: left;
        opacity: 0.5;
        font-size: 2.5rem;
        z-index: 10;
    }
    .container_type {
        max-width: 400px;
        height: 150px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .container_flex_type {
        padding: 0;
        margin: 12px;
        margin-left:0;
        margin-right: 0;
        display: inline-flex;
    }

    .flex_item_type {
        width: 140px;
        height: 105px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.flex_item_type:not(:last-of-type) {
            margin-right: 12px;
        }
    }
    .insurances_buy {
        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }
}

@media only screen and (min-width: 436px) {
    .Telecom {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        text-align: center;
        padding: 24px;
        margin-bottom: 3%;
        width: 750px;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;

        .sky_details {
            text-align: center !important;
            font-size: 0.8rem !important;
            /* display: block; */
            width: 100%;
        }

        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }

        .linemob {
            justify-content: space-evenly;
        }

        .internet {
            display: none;
            opacity: 0;
        }

        .internet--appear {
            opacity: 1;
            transition: opacity .5s ease-in;

            .select-iternet {
                display: unset;
            }
        }

        .service_disabled {
            opacity: 0.2;
            pointer-events: none;
        }

        .operator {
            opacity: 0;
            display: none;

        }

        .operator--appear {
            opacity: 1;
            transition: opacity .5s ease-in;
        }

        .telecom {
            display: none;
            opacity: 0;
        }

        .telecom--appear {
            opacity: 1;
            transition: opacity .5s ease-in;
        }

        .select {
            width: 220px;
            text-align: left;
            margin-bottom: 24px;
            margin-top: 24px;
        }

        .telecom_forfait {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        .box {
            height: 80%;
            border-radius: 9px;
            background-color: #ecdff626;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: #BDC1E7 solid 0.5px;
            margin-bottom: 15px;
            margin-top: 15px;
            text-align: center;
            height: 119px;
            width: 140px;
            color: lightgrey;
            cursor: pointer;
        }

        .logo-box {
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;

            .fa-mobile-android-alt,
            .fa-file-alt,
            .fa-sim-card {
                font-size: 2.5rem;
            }
        }

        .mobile-hr {
            display: none;
        }

        .text-box {
            padding: 10px;
            font-size: 16px;
        }

        .telecom_phone_brand,
        .telecom_phone_color,
        .telecom_phone_size {
            max-width: 220px;
            text-align: left;
            margin-bottom: 24px;
            margin-top: 24px;
        }

        .telecom_phone_brand-list,
        .telecom_phone_color-list,
        .telecom_phone_size-list {
            width: 100%;
        }

        .telecom_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
            cursor: pointer;
        }

        .telecom_operator-list {
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }

        .telecom_operator-logo {
            padding: 0;
            padding-bottom: 37px;
        }

        .telecom_operator-details {
            text-align: left;
            margin-bottom: 9px;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 500;
            font-size: 15px;
        }

        .telecom_operator-details-wrap {
            margin: auto;
            margin-right: 0;
            margin-left: 0;
            width: 390px;
            padding-left: 20px;
        }

        .telecom_operator-details-wrap-prep {
            width: 180px;
            margin-top: 12px;
        }

        .telecom_operator-price {
            line-height: 1em;
            position: relative;
            outline: 0;
            border: 0;
            color: rgba(68, 76, 165, 0.85);
            text-align: center;
            height: 1.5em;

            // opacity: .5;
            &:before {
                content: '';
                // use the linear-gradient for the fading effect
                // use a solid background color for a solid bar
                background: linear-gradient(to right, transparent, rgba(68, 76, 165, 0.85), transparent);
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 1px;
            }

            &:after {
                content: attr(data-content);
                position: relative;
                display: inline-block;
                font-weight: 700;
                padding: 0 .5em;
                line-height: 1.5em;
                font-size: 1.3rem;
                color: #583d95;
                // this is really the only tricky part, you need to specify the background color of the container element...
                background-color: #fcfcfa;
            }
        }

        .telecom_phone {
            margin: 0 auto;
            padding: 0;
        }

        .telecom_phone-list {
            width: 100%;
            text-align: left;
        }

        .telecom_phone-logo {
            width: 100px;
            height: 100px;
            margin-left: 15px;
        }

        .telecom_phone-details {
            word-break: break-word;
            text-align: left;
            color: rgba(68, 76, 165, 0.85);
            position: absolute;
            margin-left: 120px;
            font-weight: 500;
            line-height: 16px;
            font-size: 15px;
        }

        .telecom_operator-info {
            margin-bottom: 24px;
        }

        .telecom_phone-price {
            line-height: 1em;
            position: relative;
            outline: 0;
            border: 0;
            color: rgba(68, 76, 165, 0.85);
            text-align: center;
            height: 1.5em;

            // opacity: .5;
            &:before {
                content: '';
                // use the linear-gradient for the fading effect
                // use a solid background color for a solid bar
                background: linear-gradient(to right, transparent, rgba(68, 76, 165, 0.85), transparent);
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 1px;
            }

            &:after {
                content: attr(data-content);
                position: relative;
                display: inline-block;
                font-weight: 700;
                padding: 0 .5em;
                line-height: 1.5em;
                // this is really the only tricky part, you need to specify the background color of the container element...
                font-size: 1.3rem;
                color: #583d95;
                background-color: #fcfcfa;
            }
        }

        .telecom_sort_price {
            text-align: right;
            color: grey;
            font-size: 0.8rem;
            font-weight: 200;

            .fa-sort {
                color: #836bc1bf;
            }
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
        }

        .subtitle {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
        }

        .title-card {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
            font-weight: 700;
        }

        .subtitle-card {
            text-align: right !important;
            color: #3e45949c;
            font-size: 18px;
            font-weight: 400;
        }

        .headerDivider {
            border-left: 0px solid #e0dfdf;
            border-right: 1px solid #e0dfdf;
            height: 41px;
            position: inherit;
        }

        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 16px;
            text-align: justify;
        }

        .media {
            object-fit: cover;
            height: 150px;
        }

        .div-card {
            margin-left: 7%;
            width: 40%;
            min-height: 490px;
        }

        .telecom_operator-checkbox {
            opacity: 0;
            display: none;
        }

        .telecom_operator-checkbox--appear {
            opacity: 1;
            margin-top: 12px;
            transition: opacity .5s ease-in;

        }

        .telecom_promo {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        .telecom_promo-container {
            display: inline-flex;
            min-width: 635px;
            padding: 5px;
        }

        .card-telecom {
            position: relative;
            height: 240px;
            border: 1px solid #0000003d;
            border-radius: 11px;
            padding: 3px;
            padding-right: 12px;
            padding-left: 12px;
            background-color: white;
            margin-top: 24px;
            margin-bottom: 12px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;

            .button {
                /* background: -webkit-linear-gradient(45deg, #ffffff 0, #f1f1f1 100%); */
                background-color: #8a5fc6fc;
                /* background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%); */
                border-radius: 100px;
                /* border: 1px solid; */
                /* border-color: #61439e; */
                color: #ffffff;
                max-width: 96px;
                :hover{
                    background-color: #8a5fc6fc;
                }
            }

            .ribbon {
                width: 132px;
                height: 40px;
                background: #8c60c6cc;
                top: 150px;
                left: -10px;
                color: white;
                transform: translate(-17%, 42%);
                padding: 7px;
            }

            .ribbon:before {
                content: "";
                position: absolute;
                top: -10px;
                left: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid #724ea2cc;
                border-top: 5px solid transparent;
                border-bottom: 5px solid #724ea2cc;
            }

            .ribbon:after {
                content: "";
                position: absolute;
                top: 0px;
                right: -40px;
                border-left: 20px solid #8c60c6cc;
                border-right: 20px solid transparent;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
            }


            .telecom_operator-logo--img {
                margin-top: 26px;
                height: 80px;
                vertical-align: middle;
            }

            .card-display {
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                height: inherit;
            }

            .card-display-promo {
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                margin-top: 20px;
            }

            .card-display-details-promo{
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                margin-top: 20px;
            }

            .card_price-wrap {
                width: 112px;
                margin-left: 'auto';
                padding: 0;
            }

        }

        .card-price {
            color: #583d95;
            font-weight: 900;
            font-size: 21px;
            margin-bottom: 12px;
        }

        .card-old-price {
            color: #583d95;
            font-size: 15px;
        }

        .card-unit {
            color: rgba(68, 76, 165, 0.85);
            margin: auto;
            font-weight: 500;
            font-size: 14px;
        }

        .card-eng-d {
            color: rgba(68, 76, 165, 0.85);
            margin: auto;
            font-weight: 500;
            font-size: 12px;
            margin-top: 12px;
        }

        .card-eng-d-uk {
            color: rgba(68, 76, 165, 0.85);
        }

        .card-eng-m {
            display: none;
        }

        .card-sim {
            position: absolute;
            display: inline-flex;
            bottom: 4px;
            left: 0;
            color: rgba(68, 76, 165, 0.85);
            text-align: left;
            font-size: 13px;
            font-weight: 500;
        }

        .card_name-m,
        .card_name-com-m {
            display: none;
        }

        .card_name-com {
            color: rgba(68, 76, 165, 1);
            margin-left: 12px;
            font-weight: 700;
            font-size: 20px;
        }

        .telecom_icon {
            width: 200px;
        }
    }

    .card_container_footer{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .container_flex{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .wrapper_mobile{
        margin-left: 20px;
        margin-right: 20px;
        margin: auto;
            width: 300px;
    }

    .mobile_container{
        width: 250px;
        margin-bottom: 24px;
        margin-top: 12px;
        margin-right: 12px;
    }

    .sky_details-p {
        margin: 0;
    }

    .help-button {
        background: transparent;
        border: 0;
    }

    .card_bg:hover{
        box-shadow: 0 2px 6px 1px #240b38, 0 2px 8px 4px #240b38cc
    }

    .footer-telecom {
        margin-bottom: 3%;

        .button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }

    .fa-chevron-right {
        display: none;
    }

    .fa-chevron-left {
        display: none;
    }
    .container_type {
        max-width: 99%;
    }
}