@media only screen and (max-width: 435px) {

    .transport {
        max-width: 400px;
        text-align: center;
        margin: auto;
        padding: 12px;
        margin-top: 80px;

        .bank_back {
            text-align: left;
            color: #593e96;
            margin-left: 12px;
        }

        .title {
            text-align: left;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 8%;
            margin-left: 12px;
        }

        .card_transport {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .service_disabled{
            opacity: 0.2;
            pointer-events: none;
        }

        .box {
            border-radius: 9px;
            background-color: #ecdff626;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: #BDC1E7 solid 0.5px;
            margin-bottom: 15px;
            margin-top: 15px;
            text-align: center;
            height: 120px;
            width: 140px;
            color: lightgrey;
            flex: 1 0 20%;
            margin-right: 6px;
            margin-left: 6px;
        }

        .logo-box {
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;

            .fa-taxi,
            .fa-car-side {
                font-size: 2.5rem;
            }
        }

        .text-box {
            padding: 10px;
            font-size: 16px;
        }

        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }
    }
}

@media only screen and (min-width: 436px) {
    .transport {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        text-align: center;
        padding: 24px;
        margin-bottom: 3%;
        width: 800px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;

        .bank_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
            cursor: pointer;
        }

        .title {
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
        }

        .service_disabled{
            opacity: 0.2;
            pointer-events: none;
        }

        .card_transport {
            display: flex;
            justify-content: space-evenly;
        }

        .box {
            border-radius: 9px;
            background-color: #ecdff626;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: #BDC1E7 solid 0.5px;
            margin-bottom: 15px;
            margin-top: 15px;
            text-align: center;
            height: 120px;
            width: 140px;
            color: lightgrey;
            cursor: pointer;
        }

        .logo-box {
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;

            .fa-taxi,
            .fa-car-side {
                font-size: 2.5rem;
            }
        }

        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }
    }
}