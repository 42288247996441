// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

@media only screen and (max-width: 435px) {
    .Login {
        margin-top: 80px;
        text-align: center;

        .input-mail,
        .input-password,
        .input-repassword {
            width: 80%;
            margin-left: 2%;
            margin-bottom: 6%;
        }

        .signup_back {
            color: #593e96;
            text-align: left;
            margin-left: 4%;
        }

        .kep-login-facebook {
            width: 225px;
            font-size: 0.8rem;
            padding: 12px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        }

        .kep-login-facebook.metro {
            border-radius: 6px;
        }

        .title {
            text-align: center;
            color: $title_color;
            font-size: 23px;
            margin-bottom: 12px;
        }

        .forgot-input-email {
            width: 100%;
            max-width: 425px;
        }

        .error {
            color: #cc0033;
            display: flex;
            font-size: 12px;
            margin: -29px 16px 10px;
        }

        .subtitle {
            color: rgba(68, 76, 165, 0.85);
            font-size: 17px;
            margin-bottom: 4%;
            display: inline;
            text-align: center;
        }


        .connect {
            margin-bottom: 2%;
        }

        .divider-signup {
            width: 50%;
            margin-top: 10%;
            margin-left: 25%;
        }

        .button-signup {
            margin-bottom: 2%;

            .button {
                background-color: $color_grey_lighter;
                border-radius: 100px;
                border-color: #954ECC;
                color: #954ECC;
                border: solid 1px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
            }
        }

        .button-connect {
            padding-top: 3%;
            margin-bottom: 2%;

            .button {
                background-color: #954ECC;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
                width: 245px;
                height: 43px;
            }
        }

        .progress {
            .progress-bar {
                background-color: #954ECC;
            }
        }
    }

    .button_facebook {
        background-color: #3B5998;
        display: inline-flex;
        align-items: center;
        color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        padding: 0px;
        border-radius: 5px;
        border: 1px solid transparent;
        font-size: 14px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
    }

    .button_facebook{
        width: 245px;
    }
    .button_facebook-icon {
        margin-right: 10px;
        background: #3B5998;
        padding: 10px;
        border-radius: 2px;
    }

    .error-modal {
        color: #cc0033;
        font-size: 13px;
    }
    
    .login-google-btn{
        border-radius: 5px !important;
        border: 1px solid #00000026 !important;
        width: 245px;
    }
}

@media only screen and (min-width: 436px) {
    .Login {
        margin: auto;
        margin-top: 80px;
        background: white;
        text-align: center;
        padding: 24px;
        width: 550px;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;

        .input-mail,
        .input-password,
        .input-repassword {
            width: 100%;
            margin-bottom: 6%;
        }

        .login_wrap {
            display: flex;
            justify-content: space-around;
        }

        .signup_back {
            color: #593e96;
            text-align: left;
            cursor: pointer;
        }

        .kep-login-facebook {
            width: 225px;
            font-size: 0.8rem;
            padding: 12px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        }

        .kep-login-facebook.metro {
            border-radius: 6px;
        }

        .title {
            text-align: center;
            color: $title_color;
            font-size: 23px;
            margin-bottom: 12px;
            padding: 0;
        }

        .subtitle {
            color: rgba(68, 76, 165, 0.85);
            font-size: 17px;
            margin-bottom: 4%;
            display: inline;
            text-align: center;
        }

        .error {
            color: #cc0033;
            display: flex;
            font-size: 12px;
        }

        .connect {
            margin-bottom: 2%;
        }

        .divider-signup {
            width: 50%;
            margin-top: 24px;
            margin-left: 25%;
        }

        .forgot-input-email {
            width: 100%;
            max-width: 425px;
        }

        .button-signup {
            margin-bottom: 24px;
            margin-top: 12px;

            .button {
                background-color: $color_grey_lighter;
                border-radius: 100px;
                border-color: #954ECC;
                color: #954ECC;
                border: solid 1px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
            }
        }

        .button-connect {
            margin-bottom: 12px;

            .button {
                background-color: #954ECC;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
                width: 221px;
                height: 43px;
            }
        }

        .progress {
            .progress-bar {
                background-color: #954ECC;
            }
        }
    }

    .error-modal {
        color: #cc0033;
        font-size: 13px;
    }

    .button_facebook {
        cursor: pointer;
        background-color: #3B5998;
        display: inline-flex;
        align-items: center;
        color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        padding: 0px;
        border-radius: 5px;
        border: 1px solid transparent;
        font-size: 14px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
    }

    .button_facebook-icon {
        margin-right: 10px;
        background: #3B5998;
        padding: 10px;
        border-radius: 2px;
    }

    .login-google-btn{
        border-radius: 5px !important;
        border: 1px solid #00000026 !important;
    }
}