@media only screen and (max-width: 435px) {
    .car_description{
        text-align: left;
    }
    .car_provider-img{
        width: 250px;
    }
    
    .car_title{
        font-weight: 600;
        color:#5915ad
    }
    
    .car_provider_logo{
        width: 250px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .car_document{
        display: flex;
        justify-content: space-evenly;
    }
}

@media only screen and (min-width: 436px) {
    .car_description{
        text-align: left;
    }
    .car_provider-img{
        width: 250px;
    }
    
    .car_title{
        font-weight: 600;
        color:#5915ad
    }
    
    .car_provider_logo{
        width: 250px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .car_document{
        display: flex;
        justify-content: space-evenly;
    }
}