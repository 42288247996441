.cont-mod{
    padding-top: 10px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    overflow: scroll;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
canvas{
    max-width: 100%;
    height: auto !important;
}