// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

.telecom_operator-price {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: rgba(68, 76, 165, 0.85);
  text-align: center;
  height: 1.5em;

  // opacity: .5;
  &:before {
      content: '';
      // use the linear-gradient for the fading effect
      // use a solid background color for a solid bar
      background: linear-gradient(to right, transparent, rgba(68, 76, 165, 0.85), transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
  }
}

@media only screen and (max-width: 435px) {

  .Signup2 {
    margin-top:80px;
    text-align: center;
  
    .signup_back{
      color: #593e96;
      margin-left: 4%;
      text-align: left;
    }
    .error{
      color: #cc0033;
      display: flex;
      font-size: 14px;
      margin: -5% 12% 3%;
    }
    .required {
      margin: -29px 47px 10px;
      }
      
    .title {
      color: $title_color;
      font-size: 26px;
      margin-bottom: 12px;
    }
    .input-mail, .input-password, .input-repassword {
      width: 80%;
        margin-left: 2%;
      margin-bottom: 6%;

    }
    .send {
      text-align: center;
      margin-right: -15%;
      padding-top: 3%;
      margin-bottom: 12px;
        .button {
          background-color: #954ECC;
          border-radius: 100px;
          box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        }
      }
      .back {
        margin-left: -15%;
        padding-top: 3%;
        margin-bottom: 2%;
          .button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
    border-radius: 100px;
    border: 1px solid;
    border-color: #61439e;
    color: #5f429d;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)

          }
      }
      .progress {
        width:75%;
        margin-left:13%;
        .progress-bar{
          background-color: #954ECC;
        }
      }
  }
  
}

@media only screen and (min-width: 436px) {
.Signup2 {
  margin: auto;
  margin-top: 80px;
    background: white;
    text-align: center;
    padding: 24px;
    margin-bottom: 7%;
    width: 504px;
    border: #3E4594 solid 1px;
    border-radius: 8px;
    box-shadow: 3px 5px 10px 1px #cbc7d0;

    .signup_back{
      color: #593e96;
      text-align: left;
      cursor: pointer;
    }

    .input-mail, .input-password, .input-repassword {
      width: 100%;
      margin-bottom: 6%;
    }


    .error{
      color: #cc0033;
      display: flex;
      font-size: 13px;
      margin: -29px 16px 10px;
    }

    .required {
      margin: -20px 20px 0px;
      }
    .title {
      text-align: left;
      color: $title_color;
      font-size: 23px;
      margin-bottom: 12px;
      padding-left: 0px;
    }
    .send {
      padding-top: 3%;
      padding-left:0px;
        .button {
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          border-radius: 100px;
          width: 100px;
          float: right;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
        }
      }
      .progress {
        margin: auto;
        margin-top: 24px;
        margin-bottom: 48px;
        .progress-bar{
          background-color: #954ECC;
        }
      }
    }
  }