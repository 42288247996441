// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

@media only screen and (max-width: 435px) {
.Addadresses{
  margin-top: 80px;
  text-align: center;
  .input-country{
    margin-top: 1%;
}
.adresses_back{
  color: #593e96;
  margin-left: 4%;
}
.input-country, {
  font-family: 'Nunito';
  height: 52px;
  font-size: 16px;
  background: #fff0;
  border-color: #dfdde1;
  border-radius: 3px;
  color: #868587;
}
  
.css-15k3avv{
  z-index: 10;
  }
  .div-input-country{
    width: 80%;
    margin-left: 11%;
  }
  .css-vj8t7z {
    background-color: transparent;
    min-height: 53px;
  }
  .css-2o5izw:hover {
    border-color:#3f51b5;
  }
  .css-2o5izw {
    border-color:#3f51b5;
    background-color: transparent;
  }
  .css-15k3avv {
    border-color: #3f51b5;
  }
  .css-wqgs6e {
    background-color: #ecdff6;
  }

.input-state, .input-zip, .input-street, .input-zip, .input-city, .input-address-name {
  width: 80%;
  margin-left: 2%;
}

.title {
  text-align: left;
  color: $title_color;
  font-size: 26px;
  margin-bottom: 12px;
}

.form-contact {
  display: inline-block;
  text-align: left;
  .subject, .message {
    width: 100%;
  }
}
  .error{
    color: #cc0033;
    display: flex;
    font-size: 12px;
    margin: -24px 47px 10px;
  }
  .required {
    margin: -29px 47px 10px;
    }
.send {
  padding-top: 3%;
  margin-bottom: 2%;
    .button {
      background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
      border-radius: 100px;
      width: 100px;
    }
  }
}
}

@media only screen and (min-width: 436px) {
.Addadresses{
    background: white;
    text-align: center;
    padding: 24px;
    margin: auto;
    margin-top: 80px;
    width: 500px;
    border: #3E4594 solid 1px;
    border-radius: 8px;
  
    .adresses_back{
      color: #593e96;
      text-align: left;
      cursor: pointer;
  }

      .css-15k3avv{
      z-index: 10;
      }
      .div-input-country{
        width: 80%;
        margin-left: 11%;
      }
      .css-vj8t7z {
        background-color: transparent;
        min-height: 53px;
      }
      .css-2o5izw:hover {
        border-color:#3f51b5;
      }
      .css-2o5izw {
        border-color:#3f51b5;
        background-color: transparent;
      }
      .css-15k3avv {
        border-color: #3f51b5;
      }
      .css-wqgs6e {
        background-color: #ecdff6;
      }
    .input-country {
      margin-top: 1%;
      margin-bottom: 3%;
      font-family: 'Nunito';
      color: 757575;
      height: 52px;
      font-size: 16px;
      background: #fff0;
      border-color: #dfdde1;
      border-radius: 3px;
      color: #868587;
    }

    .input-state, .input-zip, .input-street, .input-zip, .input-city, .input-address-name {
      width: 80%;
      margin-left: 2%;
    }

    .title {
      color: $title_color;
      font-size: 26px;
      margin-bottom: 12px;
    }
  
    .form-contact {
      display: inline-block;
      text-align: left;
      .subject, .message {
        width: 100%;
      }
    }
      .error{
        color: #cc0033;
        display: flex;
        font-size: 12px;
      }
    .send {
      padding-top: 3%;
      margin-bottom: 2%;
        .button {
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          border-radius: 100px;
          width: 100px;
          float: right;
        }
      }
    }}