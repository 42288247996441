// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

@media only screen and (max-width: 435px) {
.Information{
      margin-top: 80px;
      text-align: center;

      .react-tel-input{
        font-family: 'Nunito';
        margin-left: 11%;
      }
  
      .information_back{
        color: #593e96;
        margin-left: 4%;
        text-align:left;
    }

      .input-phone, .react-tel-input input[type=tel], .react-tel-input input[type=text]{
        width: 80%;
        height: 52px;
        font-size: 16px;
        background: #fff0;
      }
      .select-gender, .input-name, .input-first-name, .input-nickname {
        width: 80%;
        margin-left: 2%;
      }
      
      .title {
        color: $title_color;
        font-size: 26px;
      margin-bottom: 12px;
      }
    
      .form-contact {
        display: inline-block;
        text-align: left;
        .subject, .message {
          width: 100%;
        }
      }
      .send {
        padding-top: 3%;
        margin-bottom: 2%;
        margin-right: 36px;
          .button {
            background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
            border-radius: 100px;
          }
        }
  }
}
@media only screen and (min-width: 436px) {
.Information {
  margin: auto;
  margin-top: 80px;
  background: white;
  text-align: center;
  padding: 24px;
  margin-bottom: 3%;
  width: 500px;
  border: #3E4594 solid 1px;
  border-radius: 8px;
  box-shadow: 3px 5px 10px 1px #cbc7d0;

    .react-tel-input{
      font-family: 'Nunito';
      margin-left: 11%;
    }

    .information_back{
      color: #593e96;
      text-align: left;
      cursor: pointer;
  }

    .input-phone, .react-tel-input input[type=tel], .react-tel-input input[type=text]{
      width: 80%;
      height: 52px;
      font-size: 16px;
      background: #fff0;
    }
    .react-tel-input .country-list {
      text-align: left;
      width: 468px;
    }
    
    .select-gender, .input-name, .input-first-name, .input-nickname {
      width: 80%;
      margin-left: 2%;
    }

    .title {
      color: $title_color;
      font-size: 26px;
    margin-bottom: 12px;
    }
    .form-contact {
      display: inline-block;
      text-align: left;
      .subject, .message {
        width: 100%;
      }
    }
    .send {
        .button {
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          border-radius: 100px;
          float: right;
        }
      }
    }
  }