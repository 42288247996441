@import "../../styles";

@media only screen and (max-width: 435px) {
    .welcome {
        margin-top: 90px;
        text-align: center;
        
        .logo{
            .logo-img{
                width:50%;
            }
        }

        .recover, .changemail, .resend{
            color: $text_color;
            font-size: 15px;
            color: #9096C3;
        }

        .resend_email{
            opacity: 0;
            visibility: hidden;
            margin-top: 48px;
        }

        .resend_email--appear{
            opacity: 1;
            visibility: visible;
            margin-top: 48px;            
            transition: opacity .5s ease-in;
        }

        .input-mail{
            margin-bottom: 4%
        }

        .currentmail{
            margin-top:19%;
            margin-bottom: 16%;
            font-size: 16px;
            color: #9096C3;
        }

        .recover {
            margin-top: 20%;
            text-align: left;
            color:#8A5FC9
        }
        .text {
            color: $text_color;
            font-size: 20px;
        }
        
        .error{
            color: #cc0033;
            display: table-row;
            font-size: 12px;
            margin: -1% 36% 1%;
          }

        .title {
          color: $title_color;
          font-size: 40px;
        }

        .send-newmail {
              .button {
                background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
                border-radius: 100px;
                width: 100px;
              }
            }
    }
}


@media only screen and (min-width: 436px) {
    .welcome {
        margin: auto;
        margin-top: 90px;
        text-align: center;
        .activate_account{
            display: flex;
            margin: auto;
            width: 850px;
        }
        .recover, .changemail, .resend{
            color: $text_color;
            font-size: 15px;
            color: #9096C3;
        }

        .content-check{
            text-align: left;
        }

        .resend_email{
            opacity: 0;
            visibility: hidden;
            margin-top: 48px;
        }

        .resend_email--appear{
            opacity: 1;
            visibility: visible;
            margin-top: 48px;            
            transition: opacity .5s ease-in;
        }

        .input-mail{
            margin-top: 12px;
        }

        .currentmail{
            margin-top:25%;
            margin-bottom: 16%;
            font-size: 16px;
            color: #9096C3;
        }

        .recover {
            padding-right: 21%;
            margin-top: 10%;
            color:#8A5FC9
        }

        .text {
            color: $text_color;
            font-size: 20px;
        }

        .logo{
            text-align: right;
            .logo-img{
            width:100%;

            }
        }

        .error{
            color: #cc0033;
            display: flex;
            font-size: 12px;
            margin: -1% 36% 1%;
          }

        .title {
          color: $title_color;
          font-size: 50px;
        }

        .send-newmail {
              .button {
                  margin-top: 24px;
                background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
                border-radius: 100px;
                width: 100px;
              }
            }
    }
}