@media only screen and (max-width: 436px) {
    .Ticketing {
        margin-top: 80px;

        .ticketing_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
        }

        .disponibility_mobile{
            display: none;
        }
        .search_city_list {
            width: 200px;
        }

        .select_currency{
            width: 75px;
            position: absolute;
            right: 0;
            top: 0;
        }

        .wrap_select_currency{
            position: relative;
        }

        .tiqet_card_container {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            max-height: 100vh;
            border: 1px solid #00000036;
            margin: auto;
            border-radius: 8px;
            width: 285px;
        }


        .card_container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        .tiqet_card {
            color: #3E4594;
            cursor: pointer;
            background: white;
            width: 250px;
            box-shadow: 0 0 6px 0px #0000008a;
            border-radius: 2px;
            margin-bottom: 12px;
            margin-top: 12px;
        }

        .tiqet_card_desc {
            padding: 6px;
            position: relative;
            display: flex;
            flex-wrap: wrap;
        }

        .tiqet_card_title {
            text-align: left;
            font-weight: 700;
            height: 125px;
            min-width: 200px;
        }

        .tiqet_card_rate {
            font-size: 0.8rem;
        }

        .tiqet_card_price {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 6px;
        }

        .ticketing_back {
            color: #593e96;
            margin-left: 4%;
        }

        .tiqet_activity-list {
            max-width: 300px;
        }

        .title_ticket {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-top: 12px;
        }

        .search {
            display: flex;
            justify-content: space-evenly;
        }

        .search_gender {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        .search_gender_appear {
            opacity: 1;
            visibility: visible;
            display: unset;
            transition: opacity .5s ease-in;
        }

        .search_city {
            margin: auto;
        }

        .search_city_appear {
            transition: margin .5s ease-in;
        }

        .select {
            width: 150px;
        }

        .list {
            width: 100%;
        }

        .card_issoyo {
            opacity: 0;
            visibility: hidden;
            margin: auto;
        }

        .card_issoyo_appear {
            opacity: 1;
            visibility: visible;
            transition: opacity .5s ease-in;
            margin: auto;
        }

        .card_bg {
            text-align: left;
            background-color: white;
            border: 1px solid #0000000a;
            border-radius: 11px;
            padding: 12px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;
            margin-bottom: 24px;
            min-height: 355px;
            max-height: 355px;
        }

        .card_title {
            font-size: 1.1rem;
            font-weight: 900;
            height: 52px;
            color: #3E4594;
        }

        .card_info,
        .card_date {
            display: flex;
            color: rgba(68, 76, 165, 0.85);
        }

        .card_desc {
            margin-left: 12px;
            height: 144px;
        }

        .card_desc-text {
            overflow: hidden;
            max-height: 144px;
            word-wrap: break-word;
        }

        .card_place {
            margin-bottom: 24px;
            color: rgba(68, 76, 165, 0.85);
            font-size: .9rem;
            height: 42px;
        }

        .card_desc-button {
            position: relative;
            float: right;
            margin-top: 12px;

            .button {
                background-color: #8a5fc6fc;
                border-radius: 100px;
                color: #ffffff;
                max-width: 96px;
            }
        }

        .wrapper {
            margin: auto;
            width: 300px;
        }

        .card_img,
        .card-img {
            width: 96px;
        }
        
        .telecom_sort_price{
            color: grey;
            font-size: 0.8rem;
            font-weight: 200;
        }
    }
    .insurances_buy {
        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }
}

@media only screen and (min-width: 436px) {
    .Ticketing {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        text-align: center;
        padding-top: 3%;
        margin-bottom: 3%;
        width: 700px;
        padding: 24px;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;
        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }
        .scrolltest {
            overflow-y: scroll;
            -webkit-overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        .tiqet_card_container {
            width: 650px;
            margin: auto;
            border-radius: 8px;
        }
    }

    .card_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .tiqet_card {
        cursor: pointer;
        color: #3E4594;
        background: white;
        width: 250px;
        box-shadow: 0 0 6px 0px #0000008a;
        border-radius: 2px;
        margin-bottom: 12px;
        margin-top: 12px;

        :hover {
            color: rgb(142, 83, 203);
        }
    }

    .select_currency{
        width: 75px;
        position: absolute;
        right: 0;
        top: 0;
    }

    .wrap_select_currency{
        position: relative;
    }

    .tiqet_card_desc {
        padding: 6px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }

    .tiqet_card_title {
        text-align: left;
        font-weight: 700;
        height: 125px;
        min-width: 200px;
    }

    .tiqet_card_rate {
        font-size: 0.8rem;
    }

    .tiqet_card_price {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 6px;
    }

    .ticketing_back {
        color: #593e96;
        margin-left: 4%;
        text-align: left;
    }

    .title_ticket {
        text-align: center;
        color: #3E4594;
        font-size: 25px;
        margin-top: 12px;
    }

    .search {
        display: flex;
        justify-content: space-evenly;
    }

    .select {
        width: 200px;
    }

    .search_gender {
        opacity: 0;
        visibility: hidden;
        display: none;
    }

    .search_gender_appear {
        opacity: 1;
        visibility: visible;
        display: unset;
        transition: opacity .5s ease-in;
    }

    .search_city {
        margin: auto;
    }

    .search_city_appear {
        transition: margin .5s ease-in;
    }

    .search_field {
        margin-bottom: 24px;
    }

    .list {
        width: 100%;
        text-align: left;
    }

    .card_issoyo {
        opacity: 0;
        visibility: hidden;
        margin: auto;
    }

    .card_issoyo_appear {
        opacity: 1;
        visibility: visible;
        transition: opacity .5s ease-in;
        margin: auto;
    }

    .card_bg {
        text-align: left;
        background-color: white;
        border: 1px solid #0000000a;
        border-radius: 11px;
        padding: 12px;
        box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;
        margin-bottom: 24px;
        min-height: 355px;
        max-height: 355px;
    }

    .card_title {
        font-size: 1.1rem;
        font-weight: 900;
        height: 52px;
        color: #3E4594;
    }

    .card_info,
    .card_date {
        display: flex;
        color: rgba(68, 76, 165, 0.85);
    }

    .card_desc {
        margin-left: 12px;
        height: 144px;
    }

    .card_desc-text {
        max-height: 144px;
        overflow: hidden;
        word-wrap: break-word;
    }

    .card_place {
        margin-bottom: 24px;
        color: rgba(68, 76, 165, 0.85);
        font-size: .9rem;
        height: 42px;
    }

    .card_desc-button {
        position: relative;
        float: right;
        margin-top: 12px;

        .button {
            background-color: #8a5fc6fc;
            border-radius: 100px;
            color: #ffffff;
            max-width: 96px;
        }
    }
    
    .telecom_sort_price{
        color: grey;
        font-size: 0.8rem;
        font-weight: 200;
    }

    .wrapper {
        display: inline-block;
        margin-right: 8px;
        margin-left: 8px;
        width: 300px;
    }

    .card_img,
    .card-img {
        width: 96px;
    }
}

.ScrollUpButton__Container {
    border-radius: 7px;
    background-color: #944ecc61 !important;
}