@media only screen and (max-width: 435px) {
    .Internet {
        margin-top: 80px;
        text-align: center;
        
        .internet_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
        }

        .internet_slider {
            margin: auto;
        }

        .internet_search {
            margin: auto;
        }

        .internet_operator-details {
            margin-bottom: 9px;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 500;
            font-size: 15px;
            height: 91px;
            width: 91px;
            border: 1px solid;
            border-radius: 50%;
            box-shadow: 0 0 5px 2px rgba(68, 76, 165, 0.85)38;
        }

        .internet_operator-details-wrap {
            max-width: 350px;
            margin-top: 12px;
            display: flex;
            justify-content: space-evenly;
        }

        .internet_icon {
            min-width: 187px;
            padding: 0;
        }

        .internet_fee{
            padding: 0;
        }
        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
        }

        .subtitle {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
        }

        .title-card {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
            font-weight: 700;
        }

        .subtitle-card {
            text-align: right !important;
            color: #3e45949c;
            font-size: 18px;
            font-weight: 400;
        }
        .telecom_sort_price{
            float: right;
            color: grey;
            font-size: 0.8rem;
            font-weight: 200;
            margin-right: 24px;
            .fa-sort{
            color: #836bc1bf;
            }
        }
        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 16px;
            text-align: justify;
        }

        .card-internet {
            position: relative;
            border: 1px solid #0000000a;
            border-radius: 11px;
            padding: 3px;
            padding-right: 12px;
            padding-left: 12px;
            background-color: white;
            margin: 17px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;

            .button {
                background-color: #8a5fc6fc;
                border-radius: 100px;
                color: #ffffff;
                max-width: 96px;
            }

            .card_price-wrap {
                //        text-align: center;
                justify-content: center;
                justify-content: space-between;
                display: flex;
                margin-top: 12px;
            }

            .card-display {
                display: unset;
            }

            .internet_operator-logo--img {
                height: 22px;
                vertical-align: middle;
            }
        }

        .card-price {
            color: #583d95;
            font-weight: 900;
            font-size: 1.5rem;
        }

        .card-unit {
            color: rgba(68, 76, 165, 0.85);
            margin: auto;
            font-weight: 500;
            font-size: 14px;
        }

        .card-eng-m {
            color: rgba(68, 76, 165, 0.85);
            margin: auto;
            font-weight: 500;
            font-size: 12px;
        }

        .internet_operator-details-wrap-prep {
            width: 180px;
            margin-top: 12px;
            display: inline-block;
            vertical-align: middle;
        }

        .card_zone {
            display: inline-block;
            vertical-align: middle;
        }

        .card-eng-d,
        .card_name-d {
            display: none;
        }

        .card_name-m {
            color: rgba(68, 76, 165, 0.85);
            margin-left: 12px;
            font-weight: 700;
            font-size: 14px;
        }

        .card-sim {
            display: inline-flex;
            color: rgba(68, 76, 165, 0.85);
            text-align: left;
            font-size: 11px;
            font-weight: 500;
        }

        .internet_operator-logo {
            font-size: 1.7rem;
        }

        .internet_operator-speed {
            font-size: 15px;
        }
    }

    .fa,
    .fas {
        text-shadow: 2px 2px 10px #7e75989c;
    }
    .insurances_buy {
        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }
    .telecom_promo-container {
        display: inline-flex;
        min-width: 390px;
        padding: 5px;
        margin-right: 12px;
    }

    .telecom_promo {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        height: 390px;
    }

    .telecom_operator-logo {
        padding: 0;
        padding-bottom: 14px;
        text-align: left;
        margin-top: 12px;
    }

    .card-telecom {
        position: relative;
        border: 1px solid #0000000a;
        border-radius: 11px;
        padding: 3px;
        padding-right: 12px;
        padding-left: 12px;
        background-color: white;
        margin-top: 17px;
        box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;

        .button {
            background-color: #8a5fc6fc;
            border-radius: 100px;
            color: #ffffff;
            max-width: 96px;
        }

        .ribbon {
            width: 132px;
            height: 40px;
            background: #8c60c6cc;
            top: 150px;
            left: -10px;
            color: white;
            transform: translate(-17%, 42%);
            padding: 7px;
        }

        .ribbon:before {
            content: "";
            position: absolute;
            top: -10px;
            left: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid #724ea2cc;
            border-top: 5px solid transparent;
            border-bottom: 5px solid #724ea2cc;
        }

        .ribbon:after {
            content: "";
            position: absolute;
            top: 0px;
            right: -40px;
            border-left: 20px solid #8c60c6cc;
            border-right: 20px solid transparent;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
        }

        .card_price-wrap {
            //        text-align: center;
            justify-content: center;
            justify-content: space-between;
            display: flex;
            margin-top: 12px;
            align-items: center;
        }

        .promo {
            padding: 0;
            margin: 0;
        }

        .img-promo {
            height: 70px !important;
            margin-right: 15px
        }

        .card-display-details-promo {
            display: flex;
            justify-content: space-between;
            margin-top: 74px;
            align-items: center;
        }

        .card-display {
            display: unset;
        }

        .card-display-promo {
            display: unset;
        }

        .telecom_operator-logo--img {
            height: 22px;
            vertical-align: middle;
        }
    }
    
    .card_name-m {
        color: rgba(68, 76, 165, 1);
        margin-left: 12px;
        font-weight: 700;
        font-size: 14px;
    }
    .comp{
        display: none;
    }
    .card_name-com-m {
        display: none;
    }

    .card_name-com {
        color: rgba(68, 76, 165, 1);
        margin-left: 12px;
        font-weight: 700;
        font-size: 17px;
        position: absolute;
        right: 0;
        width: 210px;
        top: 14px;
    }

    .telecom_operator-details {
        text-align: left;
        margin-bottom: 9px;
        color: rgba(68, 76, 165, 0.85);
        font-weight: 500;
        font-size: 15px;
    }

    .telecom_operator-details-wrap {
        max-width: 320px;
        margin-top: 12px
    }

    .card-old-price {
        color: #583d95;
        text-align: left;
        font-size: 15px;
    }

    .card-price {
        color: #583d95;
        font-weight: 900;
        font-size: 28px;
        text-align: left;
        margin-bottom: 12px;
    }
}

@media only screen and (min-width: 436px) {
    .Internet {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        text-align: center;
        padding: 24px;
        margin-bottom: 3%;
        width: 700px;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;

        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }

        .internet_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
            cursor: pointer;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
        }

        .telecom_sort_price{
          float: right;
          color: grey;
          font-size: 0.8rem;
          font-weight: 200;
          .fa-sort{
          color: #836bc1bf;
          }
      }

        .internet_search {
            display: flex;
            justify-content: space-evenly;
        }

        .card-display {
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            height: inherit;
        }

        .card_price-wrap {
            margin-left: 'auto';
            padding: 0;
        }

        .card-price {
            color: #583d95;
            font-weight: 900;
            font-size: 2rem;
            margin-bottom: 12px;
        }

        .card-unit {
            color: rgba(68, 76, 165, 0.85);
            margin: auto;
            font-weight: 500;
            font-size: 14px;
        }

        .card-eng-d {
            color: rgba(68, 76, 165, 0.85);
            margin: auto;
            font-weight: 500;
            font-size: 12px;
            margin-top: 12px;
        }

        .card-eng-m {
            display: none;
        }

        .card-sim {
            position: absolute;
            display: inline-flex;
            bottom: 4px;
            left: 0;
            color: rgba(68, 76, 165, 0.85);
            text-align: left;
            font-size: 13px;
            font-weight: 500;
        }

        .card_name-m {
            display: none;
        }

        .card_name-d {
            position: absolute;
            top: 15px;
            font-size: 1rem;
            color: #5e64a0;
        }

        .card-internet {
            position: relative;
            height: 225px;
            border: 1px solid #0000000a;
            border-radius: 11px;
            padding: 3px;
            padding-right: 12px;
            padding-left: 12px;
            background-color: white;
            margin-top: 24px;
            margin-bottom: 24px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;

            .button {
                background-color: #8a5fc6fc;
                border-radius: 100px;
                color: #ffffff;
                max-width: 96px;
            }

            .internet_operator-logo--img {
                height: 80px;
                vertical-align: middle;
            }
        }

        .internet_operator-details {

            margin-bottom: 9px;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 500;
            font-size: 17px;
            height: 91px;
            width: 91px;
            border: 1px solid;
            border-radius: 50%;
            box-shadow: 0 0 5px 2px rgba(68, 76, 165, 0.85)38;
        }

        .internet_operator-details-wrap {
            margin: auto;
            margin-right: 0;
            margin-left: 0;
            width: 390px;
            padding-left: 20px;
            display: flex;
            justify-content: space-evenly;
        }

        .internet_operator-details-wrap-prep {
            width: 180px;
            margin-top: 12px;
        }

        .internet_operator-logo {
            font-size: 1.7rem;
            margin-top: 2px;
        }

        .internet_operator-speed {
            font-size: 15px;
        }

        .internet_icon {
            min-width: 400px;
        }

        .fa,
        .fas {
            text-shadow: 2px 2px 10px #7e75989c;
        }
        .telecom_promo-container {
            display: inline-flex;
            min-width: 700px;
            padding: 5px;
        }

        .card-telecom {
            position: relative;
            height: 240px;
            border: 1px solid #0000003d;
            border-radius: 11px;
            padding: 3px;
            padding-right: 12px;
            padding-left: 12px;
            background-color: white;
            margin-top: 24px;
            margin-bottom: 12px;
            min-width: 430px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;

            .button {
                /* background: -webkit-linear-gradient(45deg, #ffffff 0, #f1f1f1 100%); */
                background-color: #8a5fc6fc;
                /* background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%); */
                border-radius: 100px;
                /* border: 1px solid; */
                /* border-color: #61439e; */
                color: #ffffff;
                max-width: 96px;
                :hover{
                    background-color: #8a5fc6fc;
                }
            }

            .ribbon {
                width: 132px;
                height: 40px;
                background: #8c60c6cc;
                top: 150px;
                left: -10px;
                color: white;
                transform: translate(-17%, 42%);
                padding: 7px;
            }

            .ribbon:before {
                content: "";
                position: absolute;
                top: -10px;
                left: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid #724ea2cc;
                border-top: 5px solid transparent;
                border-bottom: 5px solid #724ea2cc;
            }

            .ribbon:after {
                content: "";
                position: absolute;
                top: 0px;
                right: -40px;
                border-left: 20px solid #8c60c6cc;
                border-right: 20px solid transparent;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
            }


            .telecom_operator-logo--img {
                margin-top: 26px;
                height: 80px;
                vertical-align: middle;
            }


            .card-display {
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                height: inherit;
            }

            .card-display-promo {
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
            }

            .card-display-details-promo{
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
            }

            .card_price-wrap {
                width: 112px;
                margin-left: 'auto';
                padding: 0;
            }
        }
        .card_name-m,
        .card_name-com-m {
            display: none;
        }

        .telecom_promo {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        .card_name-com {
            color: rgba(68, 76, 165, 1);
            margin-left: 12px;
            font-weight: 700;
            font-size: 20px;
        }

        .mobile-hr {
            display: none;
        }

        .telecom_operator-details {
            text-align: left;
            margin-bottom: 9px;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 500;
            font-size: 15px;
        }

        .telecom_operator-details-wrap {
            max-width: 320px;
            margin-top: 12px
        }

        .telecom_operator-logo {
            padding: 0;
            padding-bottom: 37px;
        }

        .card-old-price {
            color: #583d95;
            font-size: 15px;
        }

        .card-price {
            color: #583d95;
            font-weight: 900;
            font-size: 21px;
            margin-bottom: 12px;
        }

        .mob { display: none }
    }
}