@import "../../../styles";

@media only screen and (max-width: 435px) {
    .Bank {
        margin-top: 80px;

        .bank_back {
            color: #593e96;
            margin-left: 4%;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 8%;
        }

        .headerDivider {
            border-left: 0px solid #e0dfdf;
            border-right: 1px solid #e0dfdf;
            height: 41px;
            position: inherit;
        }

        .subtitle {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
        }

        .wrap_button {
            display: flex;
            align-items: baseline;
            justify-content: space-around;
            margin-bottom: 24px;
        }
        
        .bank_info-icon{
            color: white;
        }
        
        .button-cat {
            cursor: pointer;
            border-bottom: 2px solid #80808047;
            color: #6c72b9;
            width: 100%;
            text-align: center;
        }

        .button-cat-select {
            cursor: pointer;
            border-bottom: 2px solid purple;
            width: 100%;
            text-align: center;
            transition: all .5s;
            color: #3e4594;
            font-weight: 700;
        }

        .title-card {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
            font-weight: 700;
        }

        .subtitle-card {
            text-align: right !important;
            color: #3e45949c;
            font-size: 18px;
            font-weight: 400;
        }

        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 15px;
            text-align: left;
        }

        .media {
            background-color: #ecdff626;
            object-fit: cover;
            height: 140px;
        }

        .card {
            background-color: #ecdff626;
            box-shadow: 0 6px 18px 0 #E3E5F6;
            width: 80%;
            margin-left: 10%;
            margin-bottom: 10%;
        }

        .telecom_promo-container {
            display: inline-flex;
            min-width: 390px;
            padding: 5px;
            margin-right: 12px;
        }

        .telecom_promo {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            height: 390px;
        }

        .telecom_operator-logo {
            padding: 0;
            padding-bottom: 14px;
            text-align: left;
            margin-top: 12px;
        }

        .card-telecom {
            position: relative;
            border: 1px solid #0000000a;
            border-radius: 11px;
            padding: 3px;
            padding-right: 12px;
            padding-left: 12px;
            background-color: white;
            margin-top: 17px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;

            .button {
                background-color: #8a5fc6fc;
                border-radius: 100px;
                color: #ffffff;
                max-width: 96px;
            }

            .card_price-wrap {
                //        text-align: center;
                justify-content: center;
                justify-content: space-between;
                display: flex;
                margin-top: 12px;
                align-items: center;
            }

            .promo {
                padding: 0;
                margin: 0;
            }

            .img-promo {
                height: 70px !important;
                margin-right: 15px
            }

            .card-display-details-promo {
                display: flex;
                justify-content: space-between;
                margin-top: 74px;
                align-items: center;
                min-height: 127px;
            }

            .card-display {
                display: unset;
            }

            .card-display-promo {
                display: unset;
            }

            .telecom_operator-logo--img {
                height: 22px;
                vertical-align: middle;
            }
        }
        
        .card_name-m {
            color: rgba(68, 76, 165, 1);
            margin-left: 12px;
            font-weight: 700;
            font-size: 14px;
        }
        .comp{
            display: none;
        }
        .card_name-com-m {
            display: none;
        }

        .ribbon {
            width: 132px;
            height: 40px;
            background: #8c60c6cc;
            top: 150px;
            left: -10px;
            color: white;
            transform: translate(-17%, 42%);
            padding: 7px;
        }

        .ribbon:before {
            content: "";
            position: absolute;
            top: -10px;
            left: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid #724ea2cc;
            border-top: 5px solid transparent;
            border-bottom: 5px solid #724ea2cc;
        }

        .ribbon:after {
            content: "";
            position: absolute;
            top: 0px;
            right: -40px;
            border-left: 20px solid #8c60c6cc;
            border-right: 20px solid transparent;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
        }

        .card_name-com {
            color: rgba(68, 76, 165, 1);
            margin-left: 12px;
            font-weight: 700;
            font-size: 17px;
            position: absolute;
            right: 0;
            width: 210px;
            top: 14px;
        }

        .telecom_operator-details {
            text-align: left;
            margin-bottom: 9px;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 500;
            font-size: 15px;
        }

        .telecom_operator-details-wrap {
            max-width: 380px;
            margin-top: 12px
        }

        .card-old-price {
            color: #583d95;
            text-align: left;
            font-size: 15px;
        }

        .card-price {
            color: #583d95;
            font-weight: 900;
            font-size: 23px;
            text-align: left;
            margin-bottom: 12px;
        }
    }
}

@media only screen and (min-width: 436px) {
    .Bank {
        margin: auto;
        margin-top: 80px;
        margin-bottom: 42px;
        background: #dadada21;
        text-align: center;
        padding: 24px;
        width: 751px;
        border: #3E4594 solid 1px;
        border-radius: 8px;

        .bank_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
            cursor: pointer;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
        }

        .subtitle {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
        }

        .wrap_button {
            display: flex;
            align-items: baseline;
            justify-content: space-around;
            margin-bottom: 24px;
        }

        .bank_info-icon{
            color: white;
        }

        .button-cat {
            cursor: pointer;
            border-bottom: 2px solid #80808047;
            width: 140px;
            text-align: center;
            color: #6c72b9;
        }

        .button-cat-select {
            cursor: pointer;
            border-bottom: 2px solid purple;
            width: 140px;
            text-align: center;
            transition: all .5s;
            color: #3e4594;
            font-weight: 700;
        }

        .title-card {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
            font-weight: 700;
        }

        .subtitle-card {
            text-align: right !important;
            color: #3e45949c;
            font-size: 18px;
            font-weight: 400;
        }

        .headerDivider {
            border-left: 0px solid #e0dfdf;
            border-right: 1px solid #e0dfdf;
            height: 41px;
            position: inherit;
        }

        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 16px;
            text-align: left;
        }

        .media {
            object-fit: cover;
            height: 150px;
        }

        .div-card {
            margin: auto;
            margin-top: 24px;
            width: 330px;
            min-height: 490px;
        }

        .telecom_promo-container {
            display: inline-flex;
            min-width: 700px;
            padding: 5px;
        }

        .card-telecom {
            position: relative;
            height: 240px;
            border: 1px solid #0000003d;
            border-radius: 11px;
            padding: 3px;
            padding-right: 12px;
            padding-left: 12px;
            background-color: white;
            margin-top: 24px;
            margin-bottom: 12px;
            min-width: 430px;
            box-shadow: 0 2px 3px #00000042, 0 2px 5px #00000038;

            .button {
                /* background: -webkit-linear-gradient(45deg, #ffffff 0, #f1f1f1 100%); */
                background-color: #8a5fc6fc;
                /* background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%); */
                border-radius: 100px;
                /* border: 1px solid; */
                /* border-color: #61439e; */
                color: #ffffff;
                max-width: 96px;
                :hover{
                    background-color: #8a5fc6fc;
                }
            }

            .ribbon {
                width: 132px;
                height: 40px;
                background: #8c60c6cc;
                top: 150px;
                left: -10px;
                color: white;
                transform: translate(-17%, 42%);
                padding: 7px;
            }

            .ribbon:before {
                content: "";
                position: absolute;
                top: -10px;
                left: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid #724ea2cc;
                border-top: 5px solid transparent;
                border-bottom: 5px solid #724ea2cc;
            }

            .ribbon:after {
                content: "";
                position: absolute;
                top: 0px;
                right: -40px;
                border-left: 20px solid #8c60c6cc;
                border-right: 20px solid transparent;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
            }


            .telecom_operator-logo--img {
                margin-top: 26px;
                height: 80px;
                vertical-align: middle;
            }


            .card-display {
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
                height: inherit;
            }

            .card-display-promo {
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
            }

            .card-display-details-promo{
                display: flex;
                justify-content: space-between;
                align-items: center;
                overflow: hidden;
            }

            .card_price-wrap {
                width: 112px;
                margin-left: 'auto';
                padding: 0;
            }
        }
        .card_name-m,
        .card_name-com-m {
            display: none;
        }

        .telecom_promo {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        .card_name-com {
            color: rgba(68, 76, 165, 1);
            margin-left: 12px;
            font-weight: 700;
            font-size: 20px;
        }

        .mobile-hr {
            display: none;
        }

        .telecom_operator-details {
            text-align: left;
            margin-bottom: 9px;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 500;
            font-size: 15px;
        }

        .telecom_operator-details-wrap {
            max-width: 320px;
            margin-top: 12px
        }

        .telecom_operator-logo {
            padding: 0;
            padding-bottom: 37px;
        }

        .card-old-price {
            color: #583d95;
            font-size: 15px;
        }

        .card-price {
            color: #583d95;
            font-weight: 900;
            font-size: 21px;
            margin-bottom: 12px;
        }

        .mob { display: none }
    }
}