@import "../../styles";

.menu_language-wrap{
    position: absolute;
    right: 11px;
    color: black;
    margin-top: 10px;
    margin-right: 24px;
}

@media only screen and (max-width: 435px) {
    .bm-menu {
        background-color: #3f2751;
    }

    .bm-cross {
        background: #3f2751;
    }

    .menu-wrap {
        width: 10%;
    }

    .my-navbar {
        button {
            color: $color_purple;
            width: 10%;

        }

        .MuiSvgIcon-root-72 {
            font-size: 29px;
        }

        .flag {
            width: 30%;
            margin-left: 58%;
            position: absolute;
        }

        .navbar-brand {
            position: absolute;
            left: 50%;
            margin-left: -50px !important;
            display: block;
        }

        .logo-horizontal-flat {
            height: 30px;
            position: absolute;
            left: 44%;
            margin-top: 10px;
            margin-left: -43px !important;
            display: block;
        }
    }

    .kep-login-facebook {
        width: 225px !important;
        font-size: 0.8rem !important;
        padding: 12px !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
    }

    .kep-login-facebook.metro {
        border-radius: 6px !important;
    }

    .button-connect {
        padding-top: 3%;
        margin-bottom: 2%;

        .button {
            background-color: #954ECC;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
            width: 221px;
            height: 43px;
        }
    }
}

@media only screen and (min-width: 436px) {
    .bm-menu {
        background-color: #3f2751;
    }

    .bm-cross {
        background: #3f2751;
    }

    .menu-wrap {
        width: 6%;
    }

    .my-navbar {
        .MuiSvgIcon-root-72 {
            font-size: 29px;
        }

        button {
            color: $color_purple;
            width: 5%;
            text-decoration: none;
        }

        .flag {
            position: absolute;
            width: 15%;
            margin-left: 76%;
        }

        .navbar-brand {
            position: absolute;
            left: 50%;
            margin-left: -50px !important;
            display: block;
        }

        .logo-horizontal-flat {
            height: 30px;
            position: absolute;
            left: 48%;
            margin-top: 10px;
            margin-left: -43px !important;
            display: block;
        }
    }

    .button-connect {
        margin-bottom: 12px;

        .button {
            background-color: #954ECC;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
            width: 221px;
            height: 43px;
        }
    }

    .kep-login-facebook {
        width: 225px !important;
        font-size: 0.8rem !important;
        padding: 12px !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
    }

    .kep-login-facebook.metro {
        border-radius: 6px !important;
    }

}