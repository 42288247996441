@media only screen and (max-width: 435px) {
    .emprunt {
        margin-top: 12px;

        .desc {
            margin-top: 22px;
        }

        .travel_title{
            font-size: 1.1rem;
            font-weight: 600;
            color: #5915ad;
        }

        .travel_info{
            margin-top: 38px;
            margin-bottom: 62px;
            background: #e2e1e1;
            padding: 12px;
        }

        .health_text{
            color: rgba(68, 76, 165, 0.85);
        }

        .emprunt_logo{
            max-width: 290px;
            margin: auto;
        }

        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }
}

@media only screen and (min-width: 436px) {
    .emprunt {
        margin-top: 42px;

        .travel_description {
            display: flex;
            justify-content: center;
            color: rgba(68, 76, 165, 0.85);
            margin-bottom: 12px;
        }

        .fa-piggy-bank,
        .fa-file-alt,
        .fa-hands-usd {
            color: #6a37a9b8;
            font-size: 2.4rem;
        }

        .desc {
            margin-right: 12px;
        }

        .travel_info{
            margin-top: 38px;
            margin-bottom: 62px;
            background: #e2e1e1;
            padding: 12px;
        }

        .travel_title{
            font-size: 1.1rem;
            font-weight: 600;
            color: #5915ad;
        }
        .travel_text{
            color: rgba(68, 76, 165, 0.85);
        }

        .emprunt_logo{
            max-width: 290px;
            margin: auto;
            margin-bottom: 24px;
        }

        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
    }
}