// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";


@media only screen and (max-width: 435px) {
  .Account {
      margin-top: 80px;
      text-align: center;
      
      .error{
        color: #cc0033;
        display: flex;
        font-size: 14px;
        margin: -5% 12% 3%;
      }

      .account_back{
        color: #593e96;
        margin-left: 4%;
        text-align:left;
    }

      .input-mail, .input-password, .input-repassword {
        width: 80%;
        margin-left: 2%;
        margin-bottom: 2%;
      }
  
      .title {
        color: $title_color;
        font-size: 26px;
      margin-bottom: 12px;
      }
    
      .form-contact {
        display: inline-block;
        text-align: left;
        .subject, .message {
          width: 100%;
        }
      }

      .send {
        margin-top: 24px;
        width: 151px;
          .button {
        width: 151px;
            background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
            border-radius: 100px;
          }
        }
        .delete {
          margin-top: 24px;
          .button {
            background: white;
            color: #bd0606;
            border: 1px solid #bd0606;
            border-radius: 100px;
            float: right;
          }
        }
  }
}

@media only screen and (min-width: 436px) {
.Account {
  margin: auto;
  margin-top: 80px;
  text-align: center;
  padding:24px;
  margin-bottom: 3%;
  width: 500px;
  border: #3E4594 solid 1px;
  border-radius: 8px;
  background: white;
  box-shadow: 3px 5px 10px 1px #cbc7d0;

  .account_back{
    color: #593e96;
    text-align: left;
    cursor: pointer;
}

    .input-mail, .input-password, .input-repassword {
      width: 80%;
      margin-bottom: 16px;
    }

    .title {
      color: $title_color;
      font-size: 26px;
    margin-bottom: 12px;
    }
  
    .error{
    color: #cc0033;
    text-align: left;
    font-size: 13px;
    margin: -13px 46px 10px;
    }

    .form-contact {
      display: inline-block;
      text-align: left;
      .subject, .message {
        width: 100%;
      }
    }

    .send {
      width: 151px;
        .button {
        width: 151px;
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          border-radius: 100px;
          float: right;
        }
      }
      .delete {
        .button {
          background: white;
          color: #bd0606;
          border: 1px solid #bd0606;
          border-radius: 100px;
          float: right;
        }
      }
    }
  }