// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

@media only screen and (max-width: 435px) {
    .country{
        color: #9096C3;
        height: 100%;
        
        .list-country{
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        height: 80%; // <-- This sets the height
        overflow: scroll;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        
        .title{
            color: $title_color;
        }

        .button-country{
            b{
                margin-left: 5%;
                transition: all 0.5s ease;
            }
            height: 50px;
            line-height: 50px;
        }

        .button-country:hover {
            height: 50px;
            line-height: 50px;
            cursor: pointer;
            background-color: #ecdff657;
            b {
            margin-left: 7%;
            transition: all 0.5s ease;
            }
        }
    }
}
}

@media only screen and (min-width: 436px) {
    .country{
        color: #9096C3;
        .title{
            color: $title_color;
        }
        .list-country{
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        height: 300px; // <-- This sets the height
        overflow: scroll;
        }
        .button-country{
            b{
                margin-left: 5%;
            transition: all 0.5s ease;

            }
            height: 50px;
            line-height: 50px;
        }

        .button-country:hover {
            height: 50px;
            line-height: 50px;
            cursor: pointer;
            background-color: #ecdff657;
            b {
            margin-left: 7%;
            transition: all 0.5s ease;
            }
        }
    }
}