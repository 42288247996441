@media only screen and (max-width: 435px) {
    .appartcity {
        padding: 12px;
        text-align: left;
        height: 100%;
        overflow: scroll;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        
        .apparticity_information_subtitle {
            margin-left: 12px;
            color: rgba(68, 76, 165, 0.85);
        }

        .error{
            color: #cc0033;
            display: flex;
            font-size: 12px;
            margin: -29px 16px 10px;
        }
        
        .appartcity_title {
            text-align: center;
            font-size: 1.3rem;
        }

        .appartcity_subtitle {
            text-align: center;
        }

        .appartcity_form {
            margin: auto;
            max-width: 440px;
        }

        .appartcity_nbr_child-age {
            margin-right: 12px;
            min-width: 120px;
        }

        .appartcity_prestation {
            width: 100%;
        }

        .appartcity_prestation-form {
            width: 100%;
            margin-top: 16px;
        }

        .appartcity_nbr_adult,
        .appartcity_nbr_child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: rgba(68, 76, 165, 0.85);
            margin-top: 16px;
            margin-bottom: 8px;

            .appartcity_nbr_adult-wrap,
            .appartcity_nbr_child-wrap {
                display: flex;
                align-items: center;
            }

            .appartcity_nbr_adult-field,
            .appartcity_nbr_child-field {
                font-size: 16px;
                font-weight: 700;
                line-height: 1.5em;
                display: block;
                text-align: center;
                min-width: 36px;
                margin: 0 4px;
            }

            .appartcity_nbr_adult-button,
            .appartcity_nbr_child-button {
                cursor: pointer;
                background-color: transparent;
                border: 2px solid #3e45948a;
                color: #3e45948a;
                border-radius: 7px;
            }
        }

        .input {
            width: 100%;

            .appartcity_destination-list,
            .appartcity_name-input,
            .appartcity_firstname-input,
            .appartcity_email-input {
                width: 100%;
            }
        }

        .react-tel-input {
            color: rgba(68, 76, 165, 0.85);
            font-family: 'Nunito', sans-serif !important;

            .form-control {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                height: 56px;
                margin-top: 16px !important;
                margin-bottom: 8px !important;
                color: rgba(68, 76, 165, 0.85);
                font-family: 'Nunito', sans-serif !important;
            }

            .selected-flag {
                z-index: 10;
            }
        }

        .appartcity_date {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        .appartcity_place,
        .appartcity_otherPlace {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        .appartcity_place--appear,
        .appartcity_otherPlace--appear {
            display: unset;
            opacity: 1;
            visibility: visible;
            transition: opacity .5s ease-in;
        }

        .appartcity_comment,
        .appartcity_company,
        .appartcity_langue {
            width: 100%;

            .appartcity_comment-input,
            .appartcity_company-input,
            .appartcity_langue-list {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 436px) {
    .appartcity {
        padding: 12px;
        text-align: left;
        height: 100%;
        overflow: scroll;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .error{
            color: #cc0033;
            display: flex;
            font-size: 12px;
            margin: -29px 16px 10px;
        }

        .apparticity_information_subtitle {
            margin-left: 12px;
            color: rgba(68, 76, 165, 0.85);
        }

        .appartcity_title {
            text-align: center;
            font-size: 1.3rem;
        }

        .appartcity_subtitle {
            text-align: center;
        }

        .appartcity_form {
            margin: auto;
            max-width: 480px;
        }

        .appartcity_nbr_child-age {
            margin-right: 12px;
            min-width: 120px;
        }

        .appartcity_prestation {
            width: 100%;
        }

        .appartcity_prestation-form {
            width: 100%;
            margin-top: 16px;
        }

        .appartcity_nbr_adult,
        .appartcity_nbr_child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: rgba(68, 76, 165, 0.85);
            margin-top: 16px;
            margin-bottom: 8px;

            .appartcity_nbr_adult-wrap,
            .appartcity_nbr_child-wrap {
                display: flex;
                align-items: center;
            }

            .appartcity_nbr_adult-field,
            .appartcity_nbr_child-field {
                font-size: 16px;
                font-weight: 700;
                line-height: 1.5em;
                display: block;
                text-align: center;
                min-width: 36px;
                margin: 0 4px;
            }

            .appartcity_nbr_adult-button,
            .appartcity_nbr_child-button {
                cursor: pointer;
                background-color: transparent;
                border: 2px solid #3e45948a;
                color: #3e45948a;
                border-radius: 7px;
            }
        }

        .input {
            .appartcity_destination-list {
                width: 196px;
            }
        }

        .react-tel-input {
            color: rgba(68, 76, 165, 0.85);
            font-family: 'Nunito', sans-serif !important;

            .form-control {
                width: 225px;
                font-size: 16px;
                font-weight: 500;
                height: 56px;
                margin-top: 16px !important;
                margin-bottom: 8px !important;
                color: rgba(68, 76, 165, 0.85);
                font-family: 'Nunito', sans-serif !important;
            }

            .selected-flag {
                z-index: 10;
            }
        }

        .appartcity_firstname-input,
        .appartcity_otherPlace-input,
        .appartcity_place-list {
            width: 225px;
        }

        .appartcity_date {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        .appartcity_place,
        .appartcity_otherPlace {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        .appartcity_place--appear,
        .appartcity_otherPlace--appear {
            display: unset;
            opacity: 1;
            visibility: visible;
            transition: opacity .5s ease-in;
        }

        .appartcity_comment,
        .appartcity_company,
        .appartcity_langue {
            width: 100%;

            .appartcity_comment-input,
            .appartcity_company-input,
            .appartcity_langue-list {
                width: 100%;
            }
        }
    }
}