// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

.home {

    background-color: $color_white;

    /* /deep/button {
    height: 48px;
  }*/
	/**START css iframe youtube to lazy load**/
		.wrapper{
			max-width: 680px;
			margin: 60px auto;
			padding: 0 20px;
			width: 70%;
		}
		@media (min-width: 801px) {
			.wrapper{
				margin: 0px;
			}
			.wrapper.dk .youtube img {
					top: 2px;
			}
		}
		@media (max-width: 800px) and (min-width: 321px){
			.wrapper.sm{
				margin: 2px auto;
				width: 77%;
			}
			.wrapper.sm .youtube img{
				top: 3px;
			}
		}
		@media (max-width: 320px) {
			.wrapper.sm{
				margin: 0px auto;
				width: 80%;
			}
			.wrapper.sm .youtube img{
				top: 3px;
			}
		}
		.youtube {
			background-color: #000;
			margin-bottom: 30px;
			position: relative;
			padding-top: 56.25%;
			overflow: hidden;
			cursor: pointer;
		}
		.youtube img {
			width: 100%;
			top: -16.82%;
			left: 0;
			opacity: 1;
		}
		.youtube .play-button {
			width: 90px;
			height: 60px;
			background-color: #333;
			box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
			z-index: 1;
			opacity: 0.8;
			border-radius: 6px;
		}
		.youtube .play-button:before {
			content: "";
			border-style: solid;
			border-width: 15px 0 15px 26.0px;
			border-color: transparent transparent transparent #fff;
		}
		.youtube img,
		.youtube .play-button {
			cursor: pointer;
		}
		.youtube img,
		.youtube iframe,
		.youtube .play-button,
		.youtube .play-button:before {
			position: absolute;
		}
		.youtube .play-button,
		.youtube .play-button:before {
			top: 50%;
			left: 50%;
			transform: translate3d( -50%, -50%, 0 );
		}
		.youtube iframe {
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
		}
/**END css iframe youtube to lazy load**/
    .image-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        width: 100%;
        height: auto;

    }

    .title {
        color: $title_color;
        //    font-size: 26px;
        font-size: 23px;
        font-weight: 800;
        letter-spacing: -0.42px;
        line-height: 27px;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 5px;
        margin-bottom: 12px;

        .why-issoyo,
        .our-partners {
            padding-top: 0px;
        }

        .improve-your-life-ab {
            font-weight: 800;
            line-height: 32px;
            text-align: justify;
        }

        .relocation-is-now-ea {
            font-weight: 800;
            letter-spacing: -0.5px;
            line-height: 32px;
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 36px;
            padding-bottom: 17px;
        }

        .it-s-easy-and-free {
            color: $text_color;
            font-weight: 600;
            text-align: right;
        }
    }

    .subtitle {
        color: $title_color;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: -0.44px;
        line-height: 22px;
        // text-align: left;
        text-align: center;
        padding-bottom: 5px;
        white-space: nowrap;

        .full-online-service,
        .easy-and-friendly,
        .connect-with-communi {}
    }

    .text {
        color: $text_color;
        font-size: 15px;
        //    font-size: 20px;
        letter-spacing: -0.25px;
        line-height: 20px;
        text-align: center;

        .manage-all-your-serv,
        .we-have-selected-the,
        .meet-new-friends-sh,
        .partnerships-allows {}

        .terms-and-conditions,
        .privacy-policy,
        .legal-notice {
            font-size: 11px;
            color: #AEAEAE;
        }

        .select-all-the-servi,
        .issoyo-provides-tail,
        .select-all-the-servi,
        .partnerships-allows {}

        .contact,
        .about-us,
        .careers {
            font-weight: bold;
            color: $color_white;
        }
    }

    .serviceLine {
        padding-bottom: 40px;
    }

    .serviceItem {
        text-align: center;

        .text {
            height: 25px;
            color: $color_purple;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: -0.44px;
            line-height: 22px;
        }
    }

    .oval {
        padding-bottom: 20px;
    }

    .ContactForm {
        text-align: center;

        .form-contact {
            display: inline-block;
            text-align: left;

            .subject,
            .message {
                width: 100%;
            }

            .send {
                .button {
                    background: linear-gradient(45deg, #60239a 0, #954dcc 100%);
                    border-radius: 100px;
                    width: 100px;
                }

                text-align: right;
            }
        }
    }

    .hide-small {
        .section {
            padding-bottom: 10%;
        }

        .content-image,
        .partnerButton,
        .section-5 {
            padding-left: 20px;
            padding-right: 20px;
        }

        .section-1 {
            margin-top: 0px;
            margin-bottom: 20%;

            .content-image {
                margin-top: -60%;

                /*   /deep/button {
          margin-top: 20px;
        }*/
                .my-button {
                    margin-top: 10%;
                    text-align: center;

                    .button {
                        font-family: 'Nunito';
                        width: 55%;
                        background: linear-gradient(45deg, #60239a 0, #954dcc 100%);
                        border-radius: 100px;
                        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                    }
                }

                .title {

                    padding-top: 0px;

                    .improve-your-life-ab,
                    .it-s-easy-and-free {
                        text-align: center;
                    }

                    .it-s-easy-and-free {
                        margin-top: 5%;
                    }
                }
            }

            /*.image-center {
          height: 490px;
      }*/
        }

        .section-2 {
            .content-image {
                margin-top: 85%;

                .title {
                    padding-top: 0px;
                }
            }
        }

        .service_description {
            padding-top: 40px;
        }

        .image-phone {
            height: auto;
            width: 49.5%;
            margin-top: -101.6%;
            -webkit-transform: rotate(26.05deg);
            transform: rotate(26.05deg);
            margin-bottom: -55.4%;
            margin-left: 31.4%;
        }

        /*.image-center {
          height: 410px;
      }*/
        .section-5 {
            padding-bottom: 5%;
        }
    }

    .section-3 {
        padding-bottom: 3%;

        .content-image {
            text-align: center;
        }

        .youtubePlayer {
            text-align: center;
            margin-top: -93%;
        }

        .benefits {
            padding-bottom: 40px;

            .oval {
                text-align: center;
            }
        }

        .benefits_description {
            padding-top: 30%;

            .benefitsButton {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    .section-4 {
        .content-image {
            padding-bottom: 5%;
            overflow: hidden;

        }

        .partnerButton {
            margin-top: -37%;
        }
    }

    .section-5 {
        .ContactForm {

            .name,
            .mail {
                width: 100%;
            }

            .error {
                color: #cc0033;
            }
        }
    .button {
        background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
        border-radius: 100px;
        width: 225px;
        border: 1px solid;
        border-color: #61439e;
    }

    .button-start{
        background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            width: 225px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
    }
        .container_start-button{
            max-width: 225px;
            margin: auto;
            box-shadow: 1px -7px 9px -4px #ecdff6;
        }
        margin-bottom: 42px;
    }
}

.hide-large {
    max-width: 1440px;
    margin: auto;
    padding: 0;
    .text {
        font-size: 18px;
    }

    .title {
        font-size: 22px;

    }

    .section {
        padding-bottom: 0%;
    }

    .section-1 {


        .my-button {
            .button {
                font-family: 'Nunito';
                background: linear-gradient(45deg, #60239a 0, #954dcc 100%);
                border-radius: 100px;
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
        }

        .content-image {
            margin-top: -30%;
        }
    }

    .section-2 {
        margin-top: auto;

        .serviceItem {
            padding-top: 12%;
        }

        /* /deep/button {
        margin-top: -20%;
      }*/
        .image-phone {
            height: auto;
            width: 25.8%;
            margin-top: -60.8%;
            transform: rotate(26.05deg);
            margin-bottom: 150px;
            margin-left: 20.3%;
        }

    }

    .section-3 {
        padding-bottom: 60px;

        .content-image {
            margin-bottom: -20px;
        }

        .youtubePlayer {
            text-align: center;
            margin-top: -67%;
            margin-left: 25.5%;
        }

        .benefits {
            padding-bottom: 20px;

            .subtitle {
                text-align: left;
            }

            .text {
                text-align: justify;
                font-size: 18px;
            }
        }

        .subtitle {
            transform: translateY(15px);
        }

        .image-center {
            margin-top: -45%;
        }

        /*    /deep/button {
        margin-top: 8%;
      }*/
    }

    .section-4 {
        padding-bottom: 0%;

        .content-image {
            margin-bottom: -22%;
        }

        .image-center {
            margin-left: 50%;
            margin-top: 15%;
            width: 100%;
            margin-bottom: -15%;
        }

        /*  /deep/button {
        margin-top: -40%;
      } */
    }

    .section-5 {
        .error {
            color: #cc0033;
        }
        .button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            width: 225px;
            border: 1px solid;
            border-color: #61439e;
        }
            .container_start-button{
                max-width: 225px;
                margin: auto;
                box-shadow: 1px -7px 9px -4px #ecdff6;
            }
            .button-start{
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            width: 225px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
            }

        //padding-bottom: 10%;
    }

    .serviceItem {
        padding-top: 50px;
    }

    .my-button {
        /* /deep/button {
        width: 300px;
      }*/
        text-align: center;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .conditionLine {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
    }
}