// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

@media only screen and (max-width: 435px) {
.Signup1{
  margin-top: 80px;
  text-align: center;

  .signup_back{
    color: #593e96;
    margin-left: 4%;
    text-align: left;
  }

  .title {
    color: $title_color;
    font-size: 26px;
    margin-bottom: 12px;
  }
  .react-tel-input{
    font-family: 'Nunito';
    margin-left: 11%;
    width: 97%;
  }

  .error{
    color: #cc0033;
    display: flex;
    font-size: 14px;
    margin: -4% 11% 3%;
  }
  .error-nick{
    color: #cc0033;
    display: flex;
    font-size: 12px;
    font-size: 14px;
    margin: -2% 12% 4%;
  }

  .back {
    margin-left: -15%;
    padding-top: 3%;
    margin-bottom: 2%;
      .button {
        background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
  border-radius: 100px;
  width: 100px;
  border: 1px solid;
  border-color: #61439e;
  color: #5f429d;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)

      }
  }

  .input-phone, .react-tel-input input[type=tel], .react-tel-input input[type=text]{
    width: 80%;
    margin-left: 2%;
    height: 52px;
    font-size: 16px;
    background: #fff0;
  }
  .select-gender, .input-name, .input-first-name, .input-nickname {
    width: 80%;
        margin-left: 2%;
  }
  .send {
    margin-right: -15%;
    padding-top: 3%;
    margin-bottom: 12px;
      .button {
        background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
        border-radius: 100px;
        width: 100px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
      }
    }
    .progress {
      width:75%;
      margin-left:13%;
      .progress-bar{
        background-color: #954ECC;
      }
    }

  }
}

@media only screen and (min-width: 436px) {
.Signup1 {
  margin: auto;
  margin-top: 80px;
    background: white;
    text-align: center;
    padding: 24px;
    margin-bottom: 3%;
    max-height: 650px;
    width: 500px;
    border: #3E4594 solid 1px;
    border-radius: 8px;
    box-shadow: 3px 5px 10px 1px #cbc7d0;

    .signup_back{
      color: #593e96;
      text-align: left;
      cursor: pointer;
    }

    .react-tel-input{
      font-family: 'Nunito';
    }
    
    .error{
      color: #cc0033;
      display: flex;
      font-size: 12px;
      margin: -29px 16px 10px;
			display: initial;
    }
    .error-nick{
      color: #cc0033;
      display: flex;
      font-size: 12px;
      margin: -7px 16px 10px;
    }

    .input-phone, .react-tel-input input[type=tel], .react-tel-input input[type=text]{
      width: 100%;
      height: 52px;
      font-size: 16px;
      background: #fff0;
    }
    .select-gender, .input-name, .input-first-name, .input-nickname {
      width: 100%;
    }

    .react-tel-input .country-list {
      text-align: left;
      width: 468px;
    }
    .title {
      text-align: left;
      color: $title_color;
      font-size: 26px;
    margin-bottom: 12px;
    padding-left: 0px;
    }
  
    .form-contact {
      display: inline-block;
      text-align: left;
      .subject, .message {
        width: 100%;
      }
    }

    .back {
      padding-top: 3%;
      padding-left: 0px;
        .button {
          background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
    border-radius: 100px;
    width: 100px;
    border: 1px solid;
    border-color: #61439e;
    color: #5f429d;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)

        }
    }

    .send {
      padding-left: 0px;
      padding-top: 3%;
        .button {
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          border-radius: 100px;
          width: 100px;
          float: right;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)

        }
      }
      .progress {
        margin: auto;
        margin-top: 24px;
        margin-bottom: 48px;
        .progress-bar{
          background-color: #954ECC;

        }
      }
    }
  }