// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";


@media only screen and (max-width: 435px) {
  .Adresses{
    margin: auto;
    margin-top: 50px;
    padding: 24px;
    .adresses_back{
      color: #593e96;
      margin-left: 4%;
    }
    .title {
      text-align: center;
      color: $title_color;
      font-size: 26px;
      margin-bottom: 24px;
    }

    .button-edit {
      text-align: right;
    }
    .anchor-card {
      text-decoration:none;
    }

    .card {
      max-width: 351px;
      margin:auto;
      margin-bottom: 24px;
      background-color: #ecdff626;
      box-shadow: 0 6px 18px 0 #E3E5F6;
      border: #BDC1E7 solid 0.5px;
      border-radius: 9px;
      box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0,0,0,0.12);

      .fa-times{
        position: absolute;
        left: 320px;
        top: 5px;
        font-size: 20px;
        color: rgb(107, 119, 178);
      }
    }

    .card-title{
      color: $title_color;
    }

    .card-text {
      color: $text_color;

    }

    .btn {
      font-family: 'Nunito';
        background-color: $color_grey_lighter;
        border-color: #954ECC;
        color: $title_color;
          border-radius: 100px;
          box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    }

    .back {
      margin-left: 44px;
      padding-top: 3%;
      margin-bottom: 2%;
        .button {
          background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
          border-radius: 100px;
          border: 1px solid;
          border-color: #61439e;
          color: #5f429d;
          width: 100px;
        }
      }
    .send {
      margin-right: 36px;
      text-align: center;
      padding-top: 3%;
      margin-bottom: 2%;
        .button {
      min-width: 100px;
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          border-radius: 100px;
          box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        }
      }
  }
}
@media only screen and (min-width: 436px) {

  .Adresses{
  margin: auto;
  margin-top: 80px;
  background: white;
  border: #3E4594 solid 1px;
  border-radius: 8px;
  text-align: center;
  padding: 24px;
  margin-bottom: 3%;
  width: 504px;
  box-shadow: 3px 5px 10px 1px #cbc7d0;

  .adresses_back{
    color: #593e96;
    text-align: left;
    cursor: pointer;
}


    .title {
      color: $title_color;
      font-size: 26px;
      margin-bottom: 12px;
    }

    .button-edit {
      text-align: right;
    }
    .anchor-card {
      text-decoration:none;
    }
    img{
      float: right;
    }
    .img-trash{
      width: 7%;
      margin-right: 5%;
    }
    .card {
    margin-bottom: 48px;
    border: #BDC1E7 solid 0.5px;
    width: 450px;
    text-align: left;
    border-radius: 9px;
   box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0,0,0,0.12);
    .fa-times{
      position: absolute;
      left: 418px;
      top: 5px;
      font-size: 20px;
      color: rgb(107, 119, 178);
    }
    }

    .card-title{
      color: $title_color;
      font-weight: 600;
    }

    .card-text {
      color: $text_color;
      margin-left: -4%;
    }

    .btn {
      font-family: 'Nunito';
        background-color: $color_grey_lighter;
        border-color: #954ECC;
        color: $title_color;
          border-radius: 100px;
          box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    }
    .back {
      margin-left: 59px;
      padding-top: 3%;
      margin-bottom: 4%;
        .button {
          background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
          border-radius: 100px;
          border: 1px solid;
          border-color: #61439e;
          color: #5f429d;
          width: 100px;
        }
      }

    .send {
        .button {
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          min-width: 100px;
          border-radius: 100px;
        }
      }
    }
  }