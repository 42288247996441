//background-color: #240038;

.Footer {
    background-color: #240038;
}

.footer-container{
    margin: auto;
    height: 100%;
    align-items: center;
    padding: 12px;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
}
.footer_link{
    display: grid;
    .issoyo_link{
        color: #ffffffcf;
    }
}
.footer_social{
    width: 100px;
    display: flex;
    justify-content: space-between;
}
.logo-rs{
    height: 23px;
}