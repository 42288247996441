@import "../../styles";

@media only screen and (max-width: 435px) {

    .Services {
        margin-top: 20%;
        
        .anchor-card {
            text-decoration: none;
        }
        .signup_back{
            color: #593e96;
            margin-left: 4%;
            text-align: left;
          }
        .image-box {
            //padding-top: 35px;
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;
            //padding-left: 35%;
            //padding-right: 35%;
        }

        .logo-box {
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;

            .fa-ticket-alt,
            .fa-newspaper,
            .fa-bus,
            .fa-vote-yea,
            .fa-lightbulb-on,
            .fa-tv-retro,
            .fa-suitcase {
                font-size: 2.5rem;
                color: #7f3fce;
            }
        }

        .text-box {
            padding-top: 10px;
            padding-bottom: 10px;
            //padding-bottom: 12%;
            font-size: 16px;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 23px;
            margin-bottom: 12px;
        }

        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 17px;
        }

        .box {
            height: 80%;
            border-radius: 10px;
            background-color: #ecdff626;
            border: #BDC1E7 solid 0.5px;
            box-shadow: 0 6px 18px 0 #E3E5F6;
            margin-bottom: 15px;
            margin-top: 15px;
            text-align: center;
            max-height: 107px;
            width: 169px;
            /* test */
            border-radius: 9px;
            background-color: #ecdff626;
            //       box-shadow: 0 6px 18px 0 #E3E5F6;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: #BDC1E7 solid 0.5px;
        }
        
        .service_disabled{
            opacity: 0.2;
            pointer-events: none;
        }
        .wish{
            width:212px;
            margin:auto;
        }

        .service_location-button {
            color: #5f429d;
            padding: 0;
            margin-left: auto;
        }

        .card-deck{
            flex-direction: initial;
        }
    }
}

@media only screen and (min-width: 436px) {
    .Services {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        text-align: center;
        padding: 24px;
        margin-bottom: 3%;
        width: 504px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;

        .signup_back{
            color: #593e96;
            text-align: left;
            cursor: pointer;
          }
          
        .anchor-card {
            text-decoration: none;
        }

        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 17px;
        }

        .box {
            cursor: pointer;
            height: 80%;
            border-radius: 9px;
            background-color: #ecdff626;
            //       box-shadow: 0 6px 18px 0 #E3E5F6;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: #BDC1E7 solid 0.5px;
            margin-bottom: 15px;
            margin-top: 15px;
            text-align: center;
            max-height: 107px;
            width: 169px;
        }

        .box-col{
            max-width: 200px;
            margin: auto;
        }

        .service_disabled{
            opacity: 0.2;
            pointer-events: none;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 23px;
            margin-bottom: 2%;
        }

        .image-box {
            //padding-top: 35px;
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;
            //padding-left: 35%;
            //padding-right: 35%;
        }
        .wish{
            width:212px;
            margin:auto;
        }
        .logo-box {
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;

            .fa-ticket-alt,
            .fa-newspaper,
            .fa-bus,
            .fa-vote-yea,
            .fa-lightbulb-on,
            .fa-tv-retro,
            .fa-suitcase {
                font-size: 2.5rem;
                color: #7f3fce;
                font-weight: 100;
            }
        }

        .service_location-button {
            color: #5f429d;
            padding: 0;
            margin-left: auto;
        }

        .text-box {
            padding-top: 10px;
            padding-bottom: 10px;
            //padding-bottom: 12%;
            font-size: 16px;
        }
    }
}