@import "../../../styles";

@media only screen and (max-width: 435px) {
    .House {
        margin-top: 80px;

        .house_back {
            color: #593e96;
            margin-left: 4%;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 8%;
        }

        .headerDivider {
            border-left: 0px solid #e0dfdf;
            border-right: 1px solid #e0dfdf;
            height: 41px;
            position: inherit;
        }

        .subtitle {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
        }

        .title-card {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
            font-weight: 700;
        }

        .subtitle-card {
            text-align: right !important;
            color: #3e45949c;
            font-size: 18px;
            font-weight: 400;
        }

        .wrap_button {
            display: flex;
            align-items: baseline;
            justify-content: space-around;
            margin-bottom: 24px;
        }

        .button-cat {
            cursor: pointer;
            border-bottom: 2px solid #80808047;
            color: #6c72b9;
            width: 100%;
            text-align: center;
        }

        .button-cat-select {
            cursor: pointer;
            border-bottom: 2px solid purple;
            width: 100%;
            text-align: center;
            transition: all .5s;
            color: #3e4594;
            font-weight: 700;
        }

        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 15px;
            text-align: left;
        }

        .media {
            background-color: #ecdff626;
            object-fit: cover;
            height: 140px;
        }

        .card {
            background-color: #ecdff626;
            box-shadow: 0 6px 18px 0 #E3E5F6;
            width: 80%;
            margin-left: 10%;
            margin-bottom: 10%;
        }
    }
}

@media only screen and (min-width: 436px) {
    .House {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        text-align: center;
        padding: 24px;
        width: 751px;
        border: #3E4594 solid 1px;
        border-radius: 8px;

        .house_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
            cursor: pointer;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
        }

        .subtitle {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
        }

        .title-card {
            text-align: left;
            color: #3E4594;
            font-size: 20px;
            font-weight: 700;
        }

        .subtitle-card {
            text-align: right !important;
            color: #3e45949c;
            font-size: 18px;
            font-weight: 400;
        }

        .headerDivider {
            border-left: 0px solid #e0dfdf;
            border-right: 1px solid #e0dfdf;
            height: 41px;
            position: inherit;
        }

        .text {
            color: rgba(68, 76, 165, 0.85);
            font-size: 16px;
            text-align: left;
        }

        .media {
            object-fit: cover;
            height: 150px;
        }

        .div-card {
            margin: auto;
            margin-top: 24px;
            width: 330px;
            min-height: 490px;
        }

        .wrap_button {
            display: flex;
            align-items: baseline;
            justify-content: space-around;
            margin-bottom: 24px;
        }

        .button-cat {
            cursor: pointer;
            border-bottom: 2px solid #80808047;
            width: 180px;
            text-align: center;
            color: #6c72b9;
        }

        .button-cat-select {
            cursor: pointer;
            border-bottom: 2px solid purple;
            width: 180px;
            text-align: center;
            transition: all .5s;
            color: #3e4594;
            font-weight: 700;
        }
    }
}