// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

@media only screen and (max-width: 435px) {
.Recover {
  margin-top: 80px;
  text-align: center;

  .input-mail, .input-password, .input-repassword {
    width: 80%;
    margin-left: 2%;
    margin-bottom: 6%;
  }

  .recover-title {
    text-align: left;
    color: $title_color;
    font-size: 23px;
    margin-bottom: 12px;
  }

  .error{
    color: #cc0033;
    display: flex;
    font-size: 12px;
    margin: -29px 16px 10px;
  }

  .recover-subtitle {
    color: rgba(68, 76, 165, 0.85);
    font-size: 17px;
    margin-bottom: 4%;
    display: inline;
    text-align: center;
  }

  .divider-signup{
    width: 50%;
    margin-top: 10%;
    margin-left:25%;
  }
  .connect { 
    margin-bottom: 2%;        
  }
  .divider-signup{
    width: 50%;
    margin-top: 10%;
    margin-left:25%;
  }
  .button-signup {
    margin-bottom: 2%;
    .button{
      background-color: $color_grey_lighter;
      border-radius: 100px;
      border-color: #954ECC;
      color: #954ECC;
      border: solid 1px;

      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    }
  }
  .button-connect {
    padding-top: 3%;
    margin-bottom: 2%;
      .button {
        background-color: #954ECC;
        border-radius: 100px;
      }
    }
    .progress {
      .progress-bar{
        background-color: #954ECC;
      }
    }
  }
}

@media only screen and (min-width: 436px) {
.Recover {
    background: white;
    text-align: center;
    padding-top: 3%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 3%;
    height: 550px;
    width: 500px;
    border: #3E4594 solid 1px;
    border-radius: 8px;

    .recover_form { 
        .input-password, .input-repassword {
            width: 100%;
            max-width: 380px;
            margin-bottom: 6%;
        }
    }

    .recover-title {
      text-align: left;
      color: $title_color;
      font-size: 23px;
      margin-bottom: 8%;
      margin-left: 10px;
    }

    .recover-subtitle {
      color: rgba(68, 76, 165, 0.85);
      font-size: 17px;
      margin-bottom: 8%;
      text-align: center;
    }
    .error{
        text-align: left;
        color: #cc0033;
        display: -webkit-flex;
        display: flex;
        font-size: 12px;
        margin: -29px 61px 10px;
    }
  
    .connect { 
      margin-bottom: 2%;        
    }

    .divider-signup{
      width: 50%;
      margin-top: 10%;
      margin-left:25%;
    }
    
    .recover_send {
      margin-bottom: 2%;
      .button{
        background-color: $color_grey_lighter;
        border-radius: 100px;
        border-color: #954ECC;
        color: #954ECC;
        border: solid 1px;

        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
      }
    }
    }
  }