// Place all the styles related to the Homepage controller here.
// They will automatically be included in application.scss.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import "../../styles";

@media only screen and (max-width: 435px) {
.Signup3 {
  margin-top: 80px;
  text-align: center;

  .signup_back{
    color: #593e96;
    margin-left: 4%;
    text-align: left;
  }

  .error-code{
    color: #cc0033;
    display: flex;
    font-size: 15;
    margin: 22px 201px -26px;
  }
  
  .text {
    margin-left: 3%;
    margin-bottom: 4%;
  }
    .input-code {
      width: 94%;
    }
    .title {
    color: #3E4594;
    font-size: 23px;
    margin-bottom: -2%;
    }

    .div-input-code {
      margin-left: 4%;
    }

    .subtitle {
      color: rgba(68, 76, 165, 0.85);
      font-size: 17px;
      margin-bottom: 4%;
      display: inline;
      display: inline;
      text-align: left;
    }

    .error{
      color: #cc0033;
      display: flex;
      font-size: 12px;
      margin: -29px 16px 10px;
    }

    .back {
      margin-left: -31%;
      padding-top: 12%;
      margin-bottom: 9%;
        .button {
          background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
  border-radius: 100px;
  border: 1px solid;
  border-color: #61439e;
  color: #5f429d;
  width: 100px;

        }
      }

    .send {
      padding-top: 12%;
      margin-bottom: 9%;
        .button {
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          border-radius: 100px;
          width: 100px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
        }
      }

      .divider-reverify{
        width: 50%;
        margin-top: 10%;
        margin-left:25%;
      }
.button-sendmail {
      padding-top: 10%;
      margin-bottom: 2%;
      .button{
        background-color: $color_grey_lighter;
        border-radius: 100px;
        border-color: #954ECC;
        color: #954ECC;
        border: solid 1px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
      }
    }

    .button-sendcode {
      padding-top: 10%;
      margin-bottom: 2%;
      .button{
        background-color: $color_grey_lighter;
        border-radius: 100px;
        border-color: #954ECC;
        color: #954ECC;
        border: solid 1px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
      }
    }
      .progress {
        width:75%;
        margin-left:13%;
        .progress-bar{
          background-color: #954ECC;
        }
      }
  }
}

@media only screen and (min-width: 436px) {
.Signup3 {
  margin: auto;
  margin-top: 80px;
    background: white;
    text-align: center;
    padding: 24px;
    margin-bottom: 3%;
    height: 600px;
    width: 500px;
    border: #3E4594 solid 1px;
    border-radius: 8px;
    
    .signup_back{
      color: #593e96;
      text-align: left;
      cursor: pointer;
    }

  .text {
    margin-left: 3%;
    margin-bottom: 4%;
  }
    .input-code {
     width: 94%;
    }

    .error{
      color: #cc0033;
      font-size: 12px;
    }
    .error-code{
      color: #cc0033;
      display: flex;
      font-size: 15px;
      margin: 22px 201px -26px;
    }
    .title {
      text-align: left;
    color: #3E4594;
    font-size: 23px;
    margin-bottom: -2%;
    }
    .div-input-code {
      margin-left: 4%;
    }

    .subtitle {
      color: rgba(68, 76, 165, 0.85);
      font-size: 17px;
      margin-bottom: 4%;
      display: inline;
      text-align: left;
    }

    .back {
      margin-left: -15%;
      padding-top: 12%;
      margin-bottom: 9%;
        .button {
          background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
    border-radius: 100px;
    width: 100px;
    border: 1px solid;
    border-color: #61439e;
    color: #5f429d;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
        }
    }
    
    .divider-reverify{
      width: 50%;
      margin-top: 10%;
      margin-left:25%;
    }
    
    .button-sendmail {
      padding-top: 10%;
      margin-bottom: 2%;
      .button{
        background-color: $color_grey_lighter;
        border-radius: 100px;
        border-color: #954ECC;
        color: #954ECC;
        border: solid 1px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
      }
    }

    
    .button-sendcode {
      padding-top: 10%;
      margin-bottom: 2%;
      .button{
        background-color: $color_grey_lighter;
        border-radius: 100px;
        border-color: #954ECC;
        color: #954ECC;
        border: solid 1px;
        float: right;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
      }
    }

    .send {
      padding-top: 12%;
      margin-bottom: 9%;
        .button {
          background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
          border-radius: 100px;
          width: 100px;
          float: right;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.15)
        }
      }
      .progress {
        margin: auto;
        margin-top: 24px;
        margin-bottom: 48px;
        max-width: 470px;
        .progress-bar{
          background-color: #954ECC;
        }
      }
    }
  }