.carLocation_description{
    text-align: left;
}

.carLocation_provider-img{
    width: 250px;
}
.carLocation{
    text-align: left;
}
.carLocation_title{
    font-weight: 600;
    color:#5915ad
}

.carLocation_depart{
    max-width: 300px;
    margin: auto;
    margin-bottom: 42px;
}

.carLocation_airport{
    width: 300px;
}

.carLocation_provider_logo{
    width: 250px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
}
.carLocation_document{
    display: flex;
    justify-content: space-evenly;
}