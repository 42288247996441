@media only screen and (max-width: 435px) {
    .scooter {
        margin-bottom: 10%;
    }

    .habitation_document {
        width: 210px;
        margin: auto;
    }
    .habitation_description {
        text-align: left;
    }

    .habitation_provider-img {
        width: 250px;
    }

    .lulo_title {
        font-weight: 600;
        color: #5915ad
    }

    .habitation_provider_logo {
        width: 250px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .insurance_description_presse{
        margin-bottom: 24px;
        text-align: center;
    }
    
    .presse_link{
        text-align: left;
    }

    .presse_link-link{
        margin-right: 24px;
    }

    .image_presse{
        width: 80px;
    box-shadow: 0 0 3px 1px #cbc7d0, 0 0 7px 4px #cbc7d087;
    border-radius: 50%;
    }
    
}

@media only screen and (min-width: 436px) {
    .habitation_description {
        text-align: left;
    }

    .habitation_provider-img {
        width: 250px;
    }

    .lulo_title {
        font-weight: 600;
        color: #5915ad
    }

    .habitation_provider_logo {
        width: 250px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .habitation_document {
        display: flex;
        justify-content: space-evenly;
    }

    .insurance_description_presse{
        margin-bottom: 24px;
        text-align: center;
    }


    .presse_link{
        text-align: left;
    }

    .presse_link-link{
        margin-right: 24px;
    }

    .image_presse{
        width: 80px;
    box-shadow: 0 0 3px 1px #cbc7d0, 0 0 7px 4px #cbc7d087;
    border-radius: 50%;
    }
}