@media only screen and (max-width: 435px) {
    .business {
        margin-top: 12px;
        text-align: left;


        .emprunt_description {
            width: 155px;
            color: rgba(68, 76, 165, 0.85);
            margin:auto;
        }

        .small_text{
            color: rgba(68, 76, 165, 0.54);
            font-size: 0.7rem;
        }

        .health_document-container{
            text-align: center;
        }

        .desc {
            margin-top: 22px;
        }

        .axa_title {
            font-weight: 600;
            color: #5915ad
        }

        .business_logo{
            max-width: 290px;
            margin: auto;
        }

        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
        
        .axa_video{
            margin-bottom: 24px;
        }
    }
}

@media only screen and (min-width: 436px) {
    .business {
        margin-top: 42px;
        text-align: left;
        
        .emprunt_description {
            display: flex;
            justify-content: center;
            color: rgba(68, 76, 165, 0.85);
            margin-bottom: 12px;
        }

        .desc {
            margin-right: 12px;
        }

        .axa_title {
            font-weight: 600;
            color: #5915ad
        }

        .health_document-container{
            text-align: center;
        }

        .small_text{
            width: 475px;
            color: rgba(68, 76, 165, 0.54);
            font-size: 0.7rem;
        }
        .business_logo{
            max-width: 290px;
            margin: auto;
            margin-bottom: 24px;
        }

        .insurances_buy-button {
            background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            color: rgba(68, 76, 165, 0.85);
        }
        
        .axa_video{
            margin-bottom: 24px;
        }
    }
}