@media only screen and (max-width: 435px) {

    .Corporate{
        max-width: 400px;
        text-align: center;
        margin: auto;
        padding: 12px;
        margin-top: 80px;

        .bank_back {
            text-align: left;
            color: #593e96;
            margin-left: 12px;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 8%;
            margin-left: 12px;
        }

        .corporate_text{
            text-align: left;
        }
        
        .corporate_offer{
            background: #84818124;
            padding: 5px;
            margin-bottom: 20px;
            margin-top: 16px;
        }

        .corporate_title{
            margin-bottom: 65px;
            text-align: left;
        }

        .corporate_offer_secretarial,
        .corporate_offer_accounting,
        .corporate_offer_payroll,
        .corporate_offer_saas {
            max-width: 265px;
            margin: auto;
            margin-bottom: 42px; 
            margin-top: 20px;
        }

        .corporate_title {
            text-emphasis: left !important;
        }

        .corporate_offer-img{
            width: 300px;
        }
        
        .corporate_info{
            margin-bottom: 20px;
        }

        .corporate_info{
            margin-bottom: 20px;
        }

        .corporate_text{
            text-align: left;
        }

        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }
    }
}

@media only screen and (min-width: 436px) {
    .Corporate {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        text-align: center;
        padding: 24px;
        margin-bottom: 3%;
        width: 800px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;

        .bank_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
            cursor: pointer;
        }

        .title {
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
        }
        
        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }

        .corporate_text{
            text-align: left;
        }
        
        .corporate_offer{
            background: #84818124;
            padding: 20px;
            margin-bottom: 20px;
            margin-top: 16px;
        }

        .corporate_title{
            margin-bottom: 65px;
        }
        .corporate_offer_secretarial,
        .corporate_offer_accounting,
        .corporate_offer_payroll,
        .corporate_offer_saas {
            width: 451px;
            margin: auto;
            margin-bottom: 42px; 
            margin-top: 20px;
        }

        .corporate_offer-img{
            width: 300px;
        }
        
        .corporate_info{
            margin-bottom: 20px;
        }
    }
}