@import "../../../styles";

@media only screen and (max-width: 435px) {

    .Energy {
        max-width: 400px;
        text-align: center;
        margin: auto;
        padding: 12px;
        margin-top: 80px;

        .bank_back {
            text-align: left;
            color: #593e96;
            margin-left: 12px;
        }

        .flex_chevron {
            position: absolute;
            width:100%;
            max-width: 400px;
        }

        .fa-chevron-right {
            position: relative;
            float: right;
            top: 40px;
            font-size: 2.5rem;
            right: 15px;
            opacity: 0.5;
            z-index: 10;
        }

        .fa-chevron-left {
            position: relative;
            top: 40px;
            float: left;
            opacity: 0.5;
            font-size: 2.5rem;
            z-index: 10;

        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 8%;
            margin-left: 12px;
        }

        .container_type {
            max-width: 400px;
            height: 150px;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        .container_flex_type {
            padding: 0;
            margin: 12px;
            margin-left:0;
            margin-right: 0;
            display: inline-flex;
        }

        .flex_item_type {
            width: 140px;
            height: 105px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.flex_item_type:not(:last-of-type) {
                margin-right: 12px;
            }
        }

        .box {
            border-radius: 9px;
            background-color: #ecdff626;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: #BDC1E7 solid 0.5px;
            margin-bottom: 15px;
            margin-top: 15px;
            text-align: center;
            max-height: 107px;
            width: 140px;
            color: lightgrey;
        }

        .logo-box {
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;

            .fa-fire,
            .fa-bolt {
                font-size: 2.5rem;
            }
        }

        .car_button {
            opacity: 0;
        }

        .car_button--appear {
            opacity: 1;
            transition: opacity .5s ease-in;
        }

        .text-box {
            padding: 10px;
            font-size: 16px;
        }

        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }
    }
}

@media only screen and (min-width: 436px) {
    .Energy {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        text-align: center;
        padding: 24px;
        margin-bottom: 6%;
        width: 800px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;

        .flex_chevron {
            display: none;
        }

        .bank_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
            cursor: pointer;
        }

        .title {
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
            text-align: center;
        }

        .container_flex_type {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        .box {
            border-radius: 9px;
            background-color: #ecdff626;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.13), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: #BDC1E7 solid 0.5px;
            margin-bottom: 15px;
            margin-top: 15px;
            text-align: center;
            max-height: 107px;
            width: 140px;
            color: lightgrey;
            cursor: pointer;
        }

        .logo-box {
            padding-top: 25px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
            width: auto;
            height: 60px;

            .fa-fire,
            .fa-bolt {
                font-size: 2.5rem;
            }
        }

        .car_button {
            opacity: 0;
        }

        .car_button--appear {
            opacity: 1;
            transition: opacity .5s ease-in;
        }

        .text-box {
            padding: 10px;
            font-size: 16px;
        }

        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }
    }
}