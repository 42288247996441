.sectionSm-1 {

    .issoyo_band {
        padding: 12px;
        background-color: #784b9a;
        background-image: -webkit-linear-gradient(180deg, #784b9a 0%, #bb84e0 100%);
        background-image: -moz-linear-gradient(180deg, #784b9a 0%, #bb84e0 100%);
        background-image: -o-linear-gradient(180deg, #784b9a 0%, #bb84e0 100%);
        background-image: linear-gradient(180deg, #784b9a 0%, #bb84e0 100%);
        margin: 50px 0 0;
        box-shadow: 0px 7px 0px -2px rgba(120, 75, 154, 0.2);
    }

    .container_header {
        padding: 36px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }

    .illustration_device-img {
        width: 280px;
    }
.service_picture{
    text-align: center;
    margin-bottom: 12px;
}
    .container_illustration{
        margin-top: 24px;
    }
    .brand {
        color: #d3a2f7;
        font-size: 2.5rem;
        line-height: 1.1em;
        padding-top: 10px;
        margin-bottom: 14px;
        font-weight: 100;
    }

    .start-button {
        width: 225px;
        height: 44px;
        color: #784b9a;
        background-color: white;
        border-radius: 25px;
    }
    .service{
        max-width: 210px;
        margin:auto;
        margin-bottom: 42px;
    }

    .service_band {
        padding: 18px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;

    }

    .container_service {
        justify-content: space-evenly;
    }

    .text-header {
        color: #e6e7f5;
        font-size: 22px;
        letter-spacing: -0.25px;
        line-height: 20px;
        text-align: left;
    }

    .service-title {
        color: #3E4594;
        text-align: center;
    }
    .menu_login{
        background-color: transparent;
        border: unset;
        position: relative;
        float: right;
        color: #e6e7f5;
        z-index: 9;
    }
    .container_start-button{
        max-width: 225px;
        margin: auto;
    }
    .service-text {
        max-width: 205px;
        text-align: center;
        color: rgba(68, 76, 165, 0.85);
        font-weight: 300;
        font-style: normal;
        line-height: 24px;
        padding-top: 8px;
        margin-bottom: 4px;
    }

    .service_traveler{
        width: 250px;
        margin: auto;
    }
    .service_traveler-img {
        width: 250px;
    }

    .service_container {
        max-width: 880px;
        margin: auto;
    }

    .illustration_device{
        width: 280px;
        margin: auto;
    }

    .fa-globe-europe,
    .fa-percent,
    .fa-comments-alt,
    .fa-lightbulb-on,
    .fa-child {
        text-align: center;
        font-size: 3rem;
        color: #784b9a;
    }
}
