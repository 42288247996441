@media only screen and (max-width: 435px) {
    .ContactModal{
        .contactmodal_title{
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
        }
    
        .error{
            color: #cc0033;
            font-size: 13px;
        }
    
        .annonce_form{
            padding-left: 24px;
            padding-right: 24px;
        }
    
        .annonce_message-input {
            width: 100%;
        }
    
        .info{
            justify-content: space-between;
        }
        
        .annonce_message_send{
            margin-top: 24px;
            .annonce_message-button {
                background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                width: 135px;
                float: right;
                }
        }
    }
}

@media only screen and (min-width: 436px) {
.ContactModal{
    .contactmodal_title{
        text-align: center;
        color: #3E4594;
        font-size: 25px;
        margin-bottom: 12px;
    }

    .error{
        color: #cc0033;
        font-size: 13px;
    }

    .annonce_form{
        padding-left: 60px;
        padding-right: 60px;
    }

    .annonce_message-input {
        width: 100%;
    }

    .info{
        justify-content: space-between;
    }
    
    .annonce_message_send{
        margin-top: 24px;
        .annonce_message-button {
            background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            width: 135px;
            float: right;
            }
    }
}
}