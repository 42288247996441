@media only screen and (max-width: 435px) {
    .habitation_insurance {

        .habitation_document {
            width: 210px;
            margin: auto;
        }

        .habitation_description {
            text-align: left;
            display: none
        }

        .habitation_description--appear {
            text-align: left;
        }
        
        .habitation_profile {
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 700;
            margin-bottom: 24px;
        }

        .habitation_profile_question {
            margin-right: 5px;
            text-align: left;
        }

        .habitation_profile_sentence {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        .home_box {
            width: 100px;
            height: 74px;
            margin-right: 12px;
            padding: 12px;
            border: 1px solid #BDC1E7;
            border-radius: 9px;
            box-shadow: 1px 1px 3px 1px lightgrey;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .habitation_provider-img {
            width: 250px;
        }

        .lulo_title {
            font-weight: 600;
            color: #5915ad
        }

        .habitation_provider_logo {
            width: 250px;
            margin: auto;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        .health_document-container {
            text-align: center;
        }
        .luko_desc_noo{
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }
}

@media only screen and (min-width: 436px) {
    .habitation_insurance {
        margin-top: 42px;

        .habitation_description {
            text-align: left;
            display: none
        }

        .habitation_description--appear {
            text-align: left;
        }

        .home_box {
            width: 100px;
            height: 74px;
            margin-right: 12px;
            padding: 12px;
            border: 1px solid #BDC1E7;
            border-radius: 9px;
            box-shadow: 1px 1px 3px 1px lightgrey;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .habitation_profile {
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(68, 76, 165, 0.85);
            font-weight: 700;
            margin-bottom: 24px;
        }

        .habitation_profile_sentence {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        .habitation_provider-img {
            width: 250px;
        }

        .lulo_title {
            font-weight: 600;
            color: #5915ad
        }

        .habitation_provider_logo {
            width: 250px;
            margin: auto;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        .habitation_document {
            display: flex;
            justify-content: space-evenly;
        }

        .health_document-container {
            text-align: center;
        }

        .luko_desc_noo{
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }
}