.section-1 {

    .issoyo_band {
        padding: 12px;
        background-color: #784b9a;
        background-image: -webkit-linear-gradient(90deg, #784b9a 0%, #bb84e0 100%);
        background-image: -moz-linear-gradient(90deg, #784b9a 0%, #bb84e0 100%);
        background-image: -o-linear-gradient(90deg, #784b9a 0%, #bb84e0 100%);
        background-image: linear-gradient(90deg, #784b9a 0%, #bb84e0 100%);
        margin: 48px 14px 0;
        border-radius: 9px;
        box-shadow: 0px 7px 0px -2px rgba(120, 75, 154, 0.2);
    }
    
    .menu_login{
        background-color: transparent;
        border: unset;
        position: relative;
        float: right;
        color: #e6e7f5;
        cursor: pointer;
    }

    .container_header {
        padding: 36px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 1140px;
        max-width: 100%;
    }

    .container_flex {
        align-items: center;
    }

    .illustration_device-img {
        width: 250px;
    }

    .text-header {
        float: right
    }

    .brand {
        color: #d3a2f7;
    }

    .start-button {
        width: 225px;
        height: 44px;
        color: #784b9a;
        border-radius: 25px;
        background-color: white;
        cursor: pointer;
    }

    .service_band {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 1140px;
        max-width: 100%;
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .container_text{
        padding-right: 50px;
    }

    .container_illustration{
        padding-left: 50px;
    }

    .container_service {
        justify-content: space-evenly;
    }

    .text-header {
        color: #e6e7f5;
        font-size: 22px;
        letter-spacing: -0.25px;
        line-height: 20px;
        text-align: left;
    }

    .service-title {
        color: #3E4594;
    }

    .service_picture, .service-title{
        text-align: center;
    }

    .service-text {
        max-width: 205px;
        text-align: center;
        color: rgba(68, 76, 165, 0.85);
        font-weight: 300;
        font-style: normal;
        line-height: 24px;
        padding-top: 8px;
        margin-bottom: 4px;
    }

    .service_traveler-img {
        width: 250px;
    }

    .service_container {
        width: 100%;
    }

    .test {
        display: block;
    }

    .fa-globe-europe,
    .fa-percent,
    .fa-comments-alt,
    .fa-lightbulb-on,
    .fa-child {
        text-align: center;
        font-size: 2rem;
        color: #784b9a;
    }
}