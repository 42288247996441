@media only screen and (max-width: 435px) {
    .expatimmo {
        padding: 12px;
        text-align: left;
        height: 100%;
        overflow: scroll;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .apparticity_information_subtitle {
            margin-left: 12px;
            color: rgba(68, 76, 165, 0.85);
        }

        .error {
            color: #cc0033;
            display: flex;
            font-size: 12px;
            margin: -29px 16px 10px;
        }

        .appartcity_title {
            text-align: center;
            font-size: 1.3rem;
        }

        .appartcity_subtitle {
            text-align: center;
        }

        .appartcity_form {
            margin: auto;
            max-width: 440px;
        }

        .appartcity_nbr_child-age {
            margin-right: 12px;
            min-width: 120px;
        }

        .appartcity_prestation {
            width: 100%;
        }

        .appartcity_prestation-form {
            width: 100%;
            margin-top: 16px;
        }

        .input {
            width: 100%;

            .appartcity_destination-list,
            .appartcity_name-input,
            .appartcity_firstname-input,
            .appartcity_email-input,
            .appartcity_budget-input {
                width: 100%;
            }
        }

        .react-tel-input {
            color: rgba(68, 76, 165, 0.85);
            font-family: 'Nunito', sans-serif !important;

            .form-control {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                height: 56px;
                margin-top: 16px !important;
                margin-bottom: 8px !important;
                color: rgba(68, 76, 165, 0.85);
                font-family: 'Nunito', sans-serif !important;
            }

            .selected-flag {
                z-index: 10;
            }
        }

        .appartcity_date {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        .appartcity_place,
        .appartcity_otherPlace {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        .appartcity_place--appear,
        .appartcity_otherPlace--appear {
            display: unset;
            opacity: 1;
            visibility: visible;
            transition: opacity .5s ease-in;
        }

        .appartcity_comment,
        .appartcity_company,
        .appartcity_langue {
            width: 100%;

            .appartcity_comment-input,
            .appartcity_company-input,
            .appartcity_langue-list {
                width: 100%;
            }
        }
    }

    .DateRangePicker_picker {
        z-index: 10 !important;
    }

    // DATE RANGE PICKER CSS 
    //rgba(60, 47, 47, 0.54)

    .CalendarDay__selected_span {
        background: #6f3e947a;
        color: white;
        border: 1px solid #6f3e9400;
    }

    .CalendarDay__selected_span:hover {
        background: #6f3e9499;
        color: white;
        border: 1px solid #6f3e9400;
    }

    // Will edit selected date or the endpoints of a range of dates
    .CalendarDay__selected {
        background: #6f3e94;
        color: white;
        border: 1px solid #e4e7e7;
    }

    // Will edit when hovered over. _span style also has this property
    .CalendarDay__selected:hover {
        background: #6f3e94;
        color: white;
    }

    // Will edit when the second date (end date) in a range of dates
    // is not yet selected. Edits the dates between your mouse and said date
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: #6f3e947a;
        border: 1px solid #e4e7e7;
        color: white;
    }

    .DateRangePicker {
        width: 100%;
    }

    .DateInput_input {
        font-family: 'Nunito', sans-serif !important;
        color: rgba(68, 76, 165, 0.85);
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right: 33px solid #6f3e94;
    }

    .DateInput_input__focused {
        border-bottom: 2px solid rgba(68, 76, 165, 0.85);
    }

    .DateRangePickerInput__withBorder {
        width: 100%;
        border-color: #3e45948a;
        border-radius: 5px;
    }
}

@media only screen and (min-width: 436px) {
    .expatimmo {
        padding: 12px;
        text-align: left;
        height: 100%;
        overflow: scroll;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .error {
            color: #cc0033;
            display: flex;
            font-size: 12px;
            margin: -29px 16px 10px;
        }

        .apparticity_information_subtitle {
            margin-left: 12px;
            color: rgba(68, 76, 165, 0.85);
        }

        .appartcity_title {
            text-align: center;
            font-size: 1.3rem;
        }

        .appartcity_subtitle {
            text-align: center;
        }

        .appartcity_form {
            margin: auto;
            max-width: 480px;
        }

        .appartcity_prestation {
            width: 100%;
        }

        .appartcity_prestation-form {
            width: 100%;
            margin-top: 16px;
        }


        .input {
            .appartcity_destination-list {
                width: 196px;
            }
        }

        .react-tel-input {
            color: rgba(68, 76, 165, 0.85);
            font-family: 'Nunito', sans-serif !important;

            .form-control {
                width: 225px;
                font-size: 16px;
                font-weight: 500;
                height: 56px;
                margin-top: 16px !important;
                margin-bottom: 8px !important;
                color: rgba(68, 76, 165, 0.85);
                font-family: 'Nunito', sans-serif !important;
            }

            .selected-flag {
                z-index: 10;
            }
        }

        .appartcity_firstname-input,
        .appartcity_place-list,
        .appartcity_budget-input {
            width: 225px;
        }

        .appartcity_date {
            margin-top: 16px;
            margin-bottom: 8px;
        }

        .appartcity_place,
        .appartcity_otherPlace {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        .appartcity_place--appear,
        .appartcity_otherPlace--appear {
            display: unset;
            opacity: 1;
            visibility: visible;
            transition: opacity .5s ease-in;
        }

        .appartcity_comment,
        .appartcity_company,
        .appartcity_langue {
            width: 100%;
            .appartcity_comment-input,
            .appartcity_company-input,
            .appartcity_langue-list {
                width: 100%;
            }
        }
    }


    .DateRangePicker_picker {
        z-index: 10 !important;
    }

    // DATE RANGE PICKER CSS 
    //rgba(60, 47, 47, 0.54)

    .CalendarDay__selected_span {
        background: #6f3e947a;
        color: white;
        border: 1px solid #6f3e9400;
    }

    .CalendarDay__selected_span:hover {
        background: #6f3e9499;
        color: white;
        border: 1px solid #6f3e9400;
    }

    // Will edit selected date or the endpoints of a range of dates
    .CalendarDay__selected {
        background: #6f3e94;
        color: white;
        border: 1px solid #e4e7e7;
    }

    // Will edit when hovered over. _span style also has this property
    .CalendarDay__selected:hover {
        background: #6f3e94;
        color: white;
    }

    // Will edit when the second date (end date) in a range of dates
    // is not yet selected. Edits the dates between your mouse and said date
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: #6f3e947a;
        border: 1px solid #e4e7e7;
        color: white;
    }

    .DateRangePicker {
        width: 100%;
    }

    .DateInput_input {
        font-family: 'Nunito', sans-serif !important;
        color: rgba(68, 76, 165, 0.85);
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right: 33px solid #6f3e94;
    }

    .DateInput_input__focused {
        border-bottom: 2px solid rgba(68, 76, 165, 0.85);
    }

    .DateRangePickerInput__withBorder {
        width: 100%;
        border-color: #3e45948a;
        border-radius: 5px;
    }
}