@media only screen and (max-width: 435px) {
    .header_logo {
        width: 250px;
        margin: auto;
        height: 150px;
    }

    .header-pic {
        width: 250px;
        box-shadow: 3px 3px 10px #3145669e;
    }
}

@media only screen and (min-width: 436px) {
    .header_logo {
        width: 250px;
        margin: auto;
    }

    .header-pic {
        width: 250px;
        box-shadow: 3px 3px 10px #3145669e;
    }
}

.cont-mod {
    padding-top: 10px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    height: 45vh; // <-- This sets the height
    overflow: scroll;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
    display: block;
    font-weight: 700;
    text-decoration: none;
    position: relative;
    //   border: 1px solid #3e45948a;
    padding: 10px;
    margin-right: 1%;
    margin-bottom: 2%;
    background-color: #8653a53d;
    //background-image: linear-gradient(to right, #ecdff61f, #5a4c9836);
    //    background: #97739a12;
    color: #3E4594;
    border-radius: 7px;
    box-shadow: 2px 3px 11px #e6e6e6;

    &:after {
        font-family: 'Font Awesome\ 5 Free' !important;
        content: '\f107';
        position: absolute;
        right: 10px;
        top: 10px;
        display: block;
        transition: transform 300ms;
    }

    &.is-open {
        &:after {
            transform: rotateZ(180deg);
        }
    }

    &.is-disabled {
        opacity: 0.5;
        background-color: grey;
    }
}

.CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
    background-color: darkslateblue;
}

.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black;
}

.text {
    color: rgba(68, 76, 165, 0.85);
}

.header_title {
    margin-top: 5%;
    height: 50px;
    text-align: center;
    font-size: 1.25rem;
    color: #3E4594;
    font-weight: 900;
}

.card-standard,
.card-black,
.card-metal {
    margin: 0 auto !important;
    padding: auto !important;
    max-width: 400px;
}

.text-description {
    color: rgba(68, 76, 165, 0.85);
    font-weight: 400;
}

.text-description-no {
    color: rgba(68, 76, 165, 0.32);
    font-weight: 400;
}

.title-description {
    color: rgba(68, 76, 165, 0.85);
    font-size: 1.25rem;
    text-align: center;
    font-weight: 900;

}

.text-price {
    text-align: center;
    font-weight: 1200;
    color: rgba(68, 76, 165, 0.85)4f;

    .price-black {
        color: rgba(68, 76, 165, 0.85);
        font-size: 1.75rem;
    }
}

.footer {
    box-shadow: 1px -7px 9px -4px #ecdff6;

    .button {
        background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
        border-radius: 100px;
        border: 1px solid;
        border-color: #61439e;
        color: rgba(68, 76, 165, 0.85);
    }
}