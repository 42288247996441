@media only screen and (max-width: 435px) {

    .Television{
        max-width: 400px;
        text-align: center;
        margin: auto;
        padding: 12px;
        margin-top: 80px;

        .bank_back {
            text-align: left;
            color: #593e96;
            margin-left: 12px;
        }

        .title {
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 8%;
            margin-left: 12px;
        }

        .television_info-wrapper-img{
            max-width: 200px;
        }

        .television_info-wrapper{
            margin: auto !important;
            max-width: 420px;
            margin-bottom: 12px !important;
            margin-top: 12px !important;
        }

        .television_info{
            margin-bottom: 20px;
        }

        .television_offer-wrapper{
            margin-bottom: 34px;
        }
        .little{
            color: rgba(68, 76, 165, 0.72);
            font-size: 0.8rem;
        }

        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }
    }
}

@media only screen and (min-width: 436px) {
    .Television {
        margin: auto;
        margin-top: 80px;
        background: #dadada21;
        border: #3E4594 solid 1px;
        border-radius: 8px;
        text-align: center;
        padding: 24px;
        margin-bottom: 3%;
        width: 800px;
        box-shadow: 3px 5px 10px 1px #cbc7d0;

        .bank_back {
            color: #593e96;
            margin-left: 4%;
            text-align: left;
            cursor: pointer;
        }

        .title {
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
        }

        .television_info-wrapper-img{
            max-width: 185px;
        }

        .television_info-wrapper{
            margin: auto !important;
            margin-bottom: 12px !important;
            margin-top: 12px !important;
        }
        
        .insurances_buy {
            .insurances_buy-button {
                background: linear-gradient(45deg, #ffffff 0, #f1f1f1 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                color: rgba(68, 76, 165, 0.85);
            }
        }
        
        .television_info{
            margin-bottom: 20px;
        }

        .television_offer-wrapper{
            margin-bottom: 34px;
        }
        .little{
            color: rgba(68, 76, 165, 0.72);
            font-size: 0.8rem;
        }
    }
}