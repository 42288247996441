.Wishlist{
    width: 100%;
    height: 800px;
}

.wish_iframe{
    width: 100%;
    height: 100%;
    border: none;
}

@media only screen and (max-width: 435px){
    .Wishlist{
        margin-top: 50px;
    }
}