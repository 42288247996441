$nav_height: 85px;

$color_purple: #60239B;
$color_purple_dark: #60239A;
$color_purple_light: #954DCC;

$color_grey_lighter:#F3F5FA;
$color_grey_light:#E3E5F6;
$color_grey:#9096C3;

$color_grey_purple:#6167A5;
$color_grey_purple_light:rgba(68, 76, 165, 0.85);

$title_color: #3E4594;
$text_color: $color_grey_purple_light;

$color_red: #ff0000;
$color_blue: #0000ff;
$color_grey: #808080;
$color_white: #ffffff;
$color_black: #000000;
$color_green: #008000;