@media only screen and (max-width: 435px) {
    .AddModal{
        .error{
            color: #cc0033;
            font-size: 13px;
        }
        
        .addmodal_title{
            text-align: center;
            color: #3E4594;
            font-size: 25px;
            margin-bottom: 12px;
        }
    
        .annonce_form{
            padding-left: 24px;
            padding-right: 24px;
    
        }
    
        .annonce_info{
            justify-content: space-between;
        }
    
        .annonce_title-input, .annonce_description-input, .annonce_type-list{
            width: 100%;
        }
    
        .annonce_title{
            width: 100%;
        }
    
        .annonce_type{
            width: 150px;
        }
        .annonce_city, .annonce_price{
            width: 150px;
        }
    
        .annonce_limit{
            color: rgba(55, 38, 68, 0.67);
            font-size: 0.7rem;
            text-align: right;
        }
    
        .annonce_send{
            margin-top: 24px;
            .ads_new-button {
                background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
                border-radius: 100px;
                border: 1px solid;
                border-color: #61439e;
                width: 135px;
                float: right;
                }
        }
    }
}

@media only screen and (min-width: 436px) {
.AddModal{

    .error{
        color: #cc0033;
        font-size: 13px;
    }
    
    .addmodal_title{
        text-align: center;
        color: #3E4594;
        font-size: 25px;
        margin-bottom: 12px;
    }

    .annonce_form{
        padding-left: 60px;
        padding-right: 60px;

    }

    .annonce_info{
        justify-content: space-between;
    }

    .annonce_title-input, .annonce_description-input, .annonce_type-list{
        width: 100%;
    }

    .annonce_title{
        width: 80%;
    }

    .annonce_type{
        width: 18%;
    }

    .annonce_limit{
        color: rgba(55, 38, 68, 0.67);
        font-size: 0.7rem;
        text-align: right;
    }

    .annonce_send{
        margin-top: 24px;
        .ads_new-button {
            background: linear-gradient(45deg,#60239a 0,#954dcc 100%);
            border-radius: 100px;
            border: 1px solid;
            border-color: #61439e;
            width: 135px;
            float: right;
            }
    }
}
}